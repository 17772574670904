import {post} from "./base";
import { statusRichiesta } from "../config/config";

export function getAll(accessToken) {
    return post("richieste/GetRichieste", {jsonBody: {accessToken}});
}

export async function add(accessToken, data) {
    // extra, cart
    await post("richieste/AddRichiesta", {jsonBody: {...data, accessToken }});
    console.log(`Richiesta campioni added`);
}

export async function update(accessToken, idRichiesta, status) {
    await post("richieste/UpdateStatusRichiesta", {jsonBody: {
        accessToken,
        idRichiesta,
        status, // statusRichiesta
    }});
    console.log(`Richiesta campioni ${idRichiesta} updated with state ${Object.keys(statusRichiesta).find((k) => statusRichiesta[k] === status)}`);
}

export async function extra(accessToken, idRichiesta, extra) {
    await post("richieste/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        extra,
        updateType: 'extra',
    }});
    console.log(`Richiesta campioni ${idRichiesta} set as ${extra ? 'extra' : 'regular'}`);
}

export async function updateCartQuantity(accessToken, idRichiesta, idCampione, quantity) {
    await post("richieste/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        idCampione,
        quantity,
        updateType: 'quantity',
    }});
    console.log(`Richiesta campioni ${idRichiesta} cart quantity updated for campione ${idCampione}`);
}

export async function removeFromCart(accessToken, idRichiesta, idCampione) {
    await post("richieste/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        idCampione,
        updateType: 'delete',
    }});
    console.log(`Richiesta campioni ${idRichiesta} got campione ${idCampione} removed from cart`);
}
