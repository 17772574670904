import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import Swal from "sweetalert2";

import { roles } from "../../config/config";
import * as apiCalendario from "../../api/calendario";
import * as apiMedici from "../../api/medici"

export const AddEvento = ({ show, setShow, user, doUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [medici, setMedici] = useState([]);

  const updateFromServer = () => {
    setLoading(true);
    apiMedici.getAll(user.authToken)
        .then((data) => setMedici(data.map((medico) => ({ value: medico.id, label: medico.fullname }))))
        .then(() => setLoading(false))
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Errore nel caricamento dei medici",
            confirmButtonColor: "#3699ff",
            confirmButtonText: "Chiudi",
          });
          console.log(err);
        }); 
  };

  useEffect(() => {
      updateFromServer();
  }, []);

  const eventSchema = Yup.object({
    title: Yup.string("Inserire un titolo valido")
      .required("Inserire un titolo valido")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 100 caratteri"),
    description: Yup.string("Inserire una descrizione valida")
      .notRequired()
      .min(3, "Minimo 3 caratteri")
      .max(255, "Massimo 500 caratteri"),
    startDate: Yup.date("Inserire una data valida").required(
      "Inserire una data valida"
    ),
    allDay: Yup.boolean(),
    endDate: Yup.date("Inserire una data valida").test(
      "required when",
      "Inserire una data di fine evento",
      function (value) {
        let allDay = this.options.parent.allDay;
        let startDate = this.options.parent.startDate;
        return !!value || (allDay && !!startDate);
      }
    ),
    startTime: Yup.string().test(
      "required when",
      "Quando è impostato un orario di fine evento, deve essere impostato l'orario di inizio evento",
      function (value) {
        let endTime = this.options.parent.endTime;
        let allDay = this.options.parent.allDay;
        return !(endTime && !value && !allDay);
      }
    ),
    endTime: Yup.string()
      .test(
        "endTime required when",
        "Quando l'orario di inizio è impostato, deve essere impostato un orario di fine evento",
        function (value) {
          let correct = true;
          let allDay = this.options.parent.allDay;
          let startTime = this.options.parent.startTime;
          if (startTime && !allDay && !value) {
            correct = false;
          }
          return correct;
        }
      )
      .test(
        "startDate same of endDate",
        "Quando la data di inizio e fine evento coincidono, l'orario di fine evento non può essere precedente a quello di inizo evento",
        function (value) {
          let startTime = moment(this.options.parent.startTime, "HH:mm");
          let startDate = this.options.parent.startDate;
          let endDate = this.options.parent.endDate;
          let endTime = moment(value, "HH:mm");
          let allDay = this.options.parent.allDay;
          return !(
            startTime &&
            moment(startDate).isSame(endDate) &&
            moment(startTime).isAfter(endTime) &&
            !allDay
          );
        }
      ),
  });

  const onCheck = () => {
    formik.setFieldValue("allDay", !formik.values.allDay);
    if (!formik.values.allDay) {
      formik.setFieldValue("startTime", "");
      formik.setFieldValue("endTime", "");
    }
  };

  const info = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Rendi l'evento visibile ad altri agenti
      {parseInt(user.idRole) === roles.admin ? " oppure a tutti" : null}
    </Tooltip>
  );

  const infoColor = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Seleziona con quale colore visualizzerai l'evento sul calendario
    </Tooltip>
  );

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      allDay: false,
      startDate: moment().format("YYYY-MM-DD"),
      startTime: "",
      endDate: "",
      endTime: "",
      backgroundColor: "#126f8b",
      visible: false,
      department: 0,
      idMedico: 0,
    },
    validationSchema: eventSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let data = {
        title: values.title,
        description: values.description,
        allDay: values.allDay ? "1" : "0",
        startDate: values.startDate,
        startTime: values.startTime,
        endDate: !!values.endDate ? values.endDate : values.startDate,
        endTime: values.endTime,
        backgroundColor: values.backgroundColor,
        department: values.visible ? values.department : -1,
        idMedico: values.idMedico,
        id: 0,
      };
      setLoading(true);
      setError(false);
      setErrorMessage("");
      apiCalendario.addEvento({ accessToken: user.authToken, ...data })
        .then(() => {
          setShow(false);
          doUpdate();
        })
        .catch((err) => {
          setError(true);
          setErrorMessage(
            "Errore non previsto, riprovare ad inserire l'evento."
          );
          console.log(err);
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="border-0 py-4">Aggiungi evento</Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group className="col-12">
              <Form.Label htmlFor="title">Titolo evento</Form.Label>
              <Form.Control
                type="text"
                id="title"
                name="title"
                placeholder="Titolo..."
                className={getInputClasses("title")}
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
              <Form.Label>Tutto il giorno?</Form.Label>
              <label className="checkbox mt-2">
                <input
                  type="checkbox"
                  color="primary"
                  className="checkbox-inline"
                  name="allDay"
                  checked={formik.values.allDay}
                  onChange={onCheck}
                />
                <span style={{ marginRight: "5px" }}></span>
                {formik.values.allDay ? "Si" : "No"}
              </label>
            </Form.Group>
            <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
              <Form.Label htmlFor="backgroundColor">
                Seleziona colore evento
                <OverlayTrigger placement="top" overlay={infoColor}>
                  <i className="ml-2 far fa-question-circle fa-sm"></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                id="backgroundColor"
                type="color"
                name="backgroundColor"
                className="col-4 ml-3"
                value={formik.values.backgroundColor}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
              <Form.Label htmlFor="startDate">Data evento</Form.Label>
              <Form.Control
                id="startDate"
                type="date"
                name="startDate"
                value={moment(formik.values.startDate).format("YYYY-MM-DD")}
                className={getInputClasses("startDate")}
                {...formik.getFieldProps("startDate")}
              />
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startDate}</div>
                </div>
              ) : null}
            </Form.Group>
            {!formik.values.allDay ? (
              <>
                <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                  <Form.Label htmlFor="startTime">Ora evento</Form.Label>
                  <Form.Control
                    id="startTime"
                    type="time"
                    name="startTime"
                    disabled={formik.values.allDay}
                    value={formik.values.allDay ? "" : formik.values.startTime}
                    className={getInputClasses("startTime")}
                    {...formik.getFieldProps("startTime")}
                  />
                  {formik.touched.startTime && formik.errors.startTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.startTime}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                </>
            ) : null}
                <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                  <Form.Label htmlFor="endDate">Data fine evento</Form.Label>
                  <Form.Control
                    id="endDate"
                    type="date"
                    name="endDate"
                    min={moment(formik.values.startDate).format("YYYY-MM-DD")}
                    value={moment(formik.values.endDate).format("YYYY-MM-DD")}
                    className={getInputClasses("endDate")}
                    {...formik.getFieldProps("endDate")}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endDate}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                {!formik.values.allDay ? (
              <>
                <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                  <Form.Label htmlFor="endTime">Ora fine evento</Form.Label>
                  <Form.Control
                    id="endTime"
                    type="time"
                    name="endTime"
                    disabled={formik.values.allDay}
                    value={formik.values.allDay ? "" : formik.values.endTime}
                    className={getInputClasses("endTime")}
                    {...formik.getFieldProps("endTime")}
                  />
                  {formik.touched.endTime && formik.errors.endTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endTime}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>{" "}
              </>
            ) : null}
            {parseInt(user.idRole) === roles.admin ? (
              <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                <Form.Label>
                  Aggiungi partecipanti
                  <OverlayTrigger placement="top" overlay={info}>
                    <i className="ml-2 far fa-question-circle fa-sm"></i>
                  </OverlayTrigger>
                </Form.Label>
                <label className="checkbox mt-2">
                  <input
                    type="checkbox"
                    color="primary"
                    className="checkbox-inline"
                    {...formik.getFieldProps("visible")}
                    checked={formik.values.visible}
                  />
                  <span style={{ marginRight: "5px" }}></span>
                  {formik.values.visible ? "Si" : "No"}
                </label>
              </Form.Group>
            ) : null}
            {formik.values.visible ? (
              <Form.Group className="col-12">
                <Form.Label htmlFor="department">
                  Seleziona dipartimento
                </Form.Label>
                <select
                  id="department"
                  className="form-control"
                  value={formik.values.department}
                  name="department"
                  onChange={formik.handleChange}
                >
                  <option value={roles.agenteCompleto}>Tutti gli agenti</option>
                  <option value={roles.agenteRoydermal}>Agenti Roydermal</option>
                  <option value={roles.agenteRoypet}>Agenti Roypet</option>
                  <option value={0}>Tutti i dipendenti interni</option>
                  <option value={roles.dipendente}>Dipendenti</option>
                  <option value={roles.grafico}>Grafici</option>
                  <option value={roles.magazziniere}>Magazzinieri</option>
                  <option value={roles.developer}>Developer</option>
                </select>
              </Form.Group>
            ) : null}
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                <Form.Label className="col-12">
                    Selezionare un medico
                    <Link to="/medici/#/new" style={{'float': 'right'}}>Aggiungi nuovo</Link>
                </Form.Label>
                <select
                    type="text"
                    name="idMedico"
                    className={`form-control ${getInputClasses("idMedico")}`}
                    {...formik.getFieldProps("idMedico")}
                >
                    <option value={0}>Medico...</option>
                    {medici.map((e, key) => (
                        <option key={key} value={e.value}>
                            {e.label}
                        </option>
                    ))}
                </select>
                {formik.touched["idMedico"] && formik.errors["idMedico"] ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors["idMedico"]}</div>
                    </div>
                ) : null}
            </Form.Group>
            <Form.Group className="col-12">
              <Form.Label htmlFor="description">Note</Form.Label>
              <textarea
                id="description"
                rows="3"
                value={formik.values.description}
                className={`form-control ${getInputClasses("description")}`}
                name="description"
                placeholder="Descrizione..."
                style={{ resize: "none" }}
                {...formik.getFieldProps("description")}
              ></textarea>
              {formik.touched.description && formik.errors.description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.description}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </Form.Row>
          <div className="col-12">
            {error ? (
              <label style={{ color: "red" }}>{errorMessage}</label>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4">
          <Button
            disabled={loading}
            onClick={formik.handleSubmit}
            type="submit"
          >
            Crea
          </Button>
          <Button
            onClick={() => {
              formik.resetForm();
              setShow(false);
            }}
          >
            Annulla
          </Button>
        </Modal.Footer>
      </Modal>{" "}
    </Form>
  );
};
