import { ShopCampioni } from "./ShopCampioni";
import { TabellaCampioni } from "./TabellaCampioni";
import { ShopMateriale } from "./ShopMateriale";
import { TabellaMateriale } from "./TabellaMateriale";
import { Richieste } from "./Richieste";
import { Servizi } from "./Servizi";

export const Campioni = {
  ShopCampioni: ShopCampioni,
  TabellaCampioni: TabellaCampioni,
  ShopMateriale: ShopMateriale,
  TabellaMateriale: TabellaMateriale,
  Richieste: Richieste,
  Servizi: Servizi,
};
