import { createSlice } from "@reduxjs/toolkit";

const initialShopState = {
  campioniCart: {},
  campioniCartSconto: {},
  materialeCart: {},
  materialeCartSconto: {},
  venditaCart: {},
  venditaCartSconto: {},
};

export const shopSlice = createSlice({
  name: "shop",
  initialState: initialShopState,
  reducers: {
    setCartQuantity: (state, action) => {
      const { id, newQuantity, cartName } = action.payload;
      console.assert((cartName + "Cart") in state);
      if (newQuantity <= 0) {
        if (id in state[cartName + "Cart"]) delete state[cartName + "Cart"][id];
      } else {
        state[cartName + "Cart"][id] = newQuantity;
      }
    },
    setCartSconto: (state, action) => {
      const { id, newSconto, cartName } = action.payload;
      console.assert((cartName + "CartSconto") in state);
      if ((typeof newSconto === 'number' && newSconto <= 0) || !newSconto) {
        if (id in state[cartName + "CartSconto"]) delete state[cartName + "CartSconto"][id];
      } else {
        state[cartName + "CartSconto"][id] = newSconto;
      }
    },
    resetCart: (state, action) => {
      const { cartName } = action.payload;
      console.assert((cartName + "Cart") in state);
      state[cartName + "Cart"] = {};
      if ((cartName + "CartSconto") in state) {
        state[cartName + "CartSconto"] = {};
      }
    }
  }
});
