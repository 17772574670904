import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { shopSlice } from "../app/modules/Shop/_redux/shopSlice";

// ELIMINA DA QUI IN POI 
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
// import { usersSlice } from "../app/modules/Admin/_redux/users/usersSlice";
// import { doctorsSlice } from "../app/modules/Admin/_redux/doctors/doctorsSlice";
// import { remarksSlice as doctorRemarksSlice } from "../app/modules/Admin/_redux/remarks/remarksSlice";
// import { specificationsSlice as doctorSpecificationsSlice } from "../app/modules/Admin/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  shop: shopSlice.reducer, // ELIMINA DA QUI IN POI
  // customers: customersSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer,
  // users: usersSlice.reducer,
  // doctors: doctorsSlice.reducer,
  // doctorRemarks: doctorRemarksSlice.reducer,
  // doctorSpecifications: doctorSpecificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
