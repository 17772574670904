import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ShopGrid, ShopGridSkeleton } from "../../modules/Shop/ShopGrid";
import * as apiMateriali from "../../api/materiali";
import * as apiRichiesteMateriale from "../../api/richiesteMateriale";

export const ShopMateriale = ({ elements }) => {
  const [loading, setLoading] = useState(false);
  const [materiali, setMateriali] = useState([]);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const updateFromServer = () => {
    setLoading(true);
    apiMateriali
      .getAll(user.authToken)
      .then((data) => {
        setMateriali(
          data.map((c) => ({
            id: c.id,
            name: c.name,
            description: c.description,
            division: c.division,
            imageUrl: c.image,
            quantity: c.quantity,
            cost: c.price,
            available: c.available,
          }))
        );
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateFromServer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <ShopGrid
      elements={materiali}
      cartName="materiale"
      displayName="confezioni"
      maxDescriptionLength={50}
      imageHeight={150}
      api={apiRichiesteMateriale}
      richiediExtra={true}
      redirectTo="/status-campionatura-servizi#materiale-pubblicitario"
    />)
    : (<ShopGridSkeleton />);
};
