import moment from "moment-timezone";

import {post} from "./base";
import { statusRichiestaServizio } from "../config/config";

export async function getAll(accessToken) {
    const result = await post("servizi/GetRichieste", {jsonBody: {accessToken}});
    return result.map((u) => ({
        ...u,
        agenteFullname: !u.agenteName ? '' : u.agenteName + " " + u.agenteLastname,
        medicoFullname: !u.medicoName ? '' : u.medicoName + " " + u.medicoLastname,
        costo: (!!u.costo && u.costo > 0) ? u.costo : undefined,
        stimaCosto: (!!u.stimaCosto && u.stimaCosto > 0) ? u.stimaCosto : undefined,
        status: parseInt(u.status),
        dateCreated: moment(u.dateCreated),
        dateUpdated: moment(u.dateUpdated),
    }));
    // idRichiesta, costo, description, status, dateCreated, dateUpdated, agenteName, agenteLastname, agenteFullname, medicoName, medicoLastname, medicoFullname, medicoMail, medicoPhone
}

export async function add(accessToken, data) {
    // data should contain 'idMedico', 'description', 'type'
    await post("servizi/AddRichiesta", {jsonBody: {...data, accessToken }});
    console.log(`Richiesta servizio added`);
}

export async function update(accessToken, idRichiesta, status, cost) {
    await post("servizi/UpdateStatusRichiesta", {jsonBody: {
        accessToken,
        idRichiesta, 
        status,
        cost: cost, // can be undefined
    }});
    console.log(`Richiesta servizio ${idRichiesta} updated with state ${Object.keys(statusRichiestaServizio).find((k) => statusRichiestaServizio[k] === status)} and cost ${cost}`);
}