
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Chip, Avatar } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Skeleton } from "@material-ui/lab";
import * as PropTypes from "prop-types";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger, Button, Modal } from "react-bootstrap";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

//import { EditNewCampione } from "./components/EditNewCampione";
import { roles, icons } from "../../config/config";
import * as apiCalendario from "../../api/calendario";

// Useful sub-components
const TableComponent = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className="table table-hover table-head-custom table-vertical-center overflow-hidden"
  />
);

const Toolbar = ({from, setFrom, to, setTo, ...props }) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
    <div {...props}>
      <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/yyyy"
          margin="normal"
          id="from"
          label="Da"
          value={from}
          onChange={setFrom}
          KeyboardButtonProps={{
            'aria-label': 'cambia la data',
          }}
        />
      <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/yyyy"
          margin="normal"
          id="to"
          label="A"
          value={to}
          onChange={setTo}
          KeyboardButtonProps={{
            'aria-label': 'cambia la data',
          }}
        />
    </div>
    </MuiPickersUtilsProvider>
  );
};

const FilterCell = (props) => {
  const { column } = props;
  if (column.name === "actions") {
    const ActionsFilterCell = ({ filter, onFilter }) => (
      <th style={{ fontWeight: "normal" }}>
        <input disabled style={{ visibility: "hidden" }} />
      </th>
    );
    ActionsFilterCell.propTypes = {
      filter: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
      onFilter: PropTypes.func.isRequired,
    };
    ActionsFilterCell.defaultProps = {
      filter: null,
    };
    return <ActionsFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const DateFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => moment(value).format("DD MMMM YYYY")}
    {...props}
  />
);

// const AllDayFormattedColumn = (props) => (
//   <DataTypeProvider
//     formatterComponent={({ value }) => (
//       <>
//         {parseInt(value)
//           ? <Avatar style={{ width: 25, height: 25, backgroundColor: 'var(--success)', }}>
//             <span className="svg-icon svg-icon-md svg-icon-white">
//               <SVG src={toAbsoluteUrl(icons.ok)} />
//             </span>
//             {/* <i className="ki ki-plus icon-white"></i> */}
//           </Avatar>
//           : null}
//       </>
//     )}
//     {...props}
//   />
// );

const ActionsColumn = ({ isAdmin, editCallback, deleteCallback }) => (
  <DataTypeProvider
    for={["actions"]}
    formatterComponent={({ value }) => (
      <> 
        { isAdmin || (value.department === -1 )
          ? <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">Modifica</Tooltip>}
          >
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
              onClick={() => editCallback(value.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(icons.edit)} />
              </span>
            </a>
          </OverlayTrigger>
          : null }
        { isAdmin || (value.department === -1 )
          ? <OverlayTrigger
            overlay={<Tooltip id="products-delete-tooltip">Elimina</Tooltip>}
          >
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => deleteCallback(value.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl(icons.delete)} />
              </span>
            </a>
          </OverlayTrigger>
          : null }
      </>
    )}
  />
);

const DeleteModal = ({ name, show, hideCallback, deleteCallback }) => (
  <Modal show={show} onHide={hideCallback} className="card-custom gutter-b">
    <Modal.Header className="border-0 py-5 row">
      <Modal.Title>
        <h3 className="font-weight-bolder text-dark">Elimina evento</h3>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-4">
      Sei sicuro di voler eliminare definitivamente "{name}"
    </Modal.Body>
    <Modal.Footer className="border-0 py-5 row">
      <Button onClick={deleteCallback} variant="danger" className="float-right">
        Elimina
      </Button>
      <Button
        onClick={hideCallback}
        variant="secondary"
        className="float-right"
      >
        Annulla
      </Button>
    </Modal.Footer>
  </Modal>
);

// Columns definition
const columns = [
  { name: "title", title: "Nome" },
  { name: "startDate", title: "Inizio" },
  { name: "endDate", title: "Fine" },
  //{ name: "allDay", title: "?" },
  { name: "department", title: "Per" },
  { name: "actions", title: "Azioni" },
];
const columnExtensions = [
  { columnName: "name", wordWrapEnabled: true},
  { columnName: "dateCreated", wordWrapEnabled: true },
];

// Messages
const tableMessages = {
  noData: "Nessun evento inserto",
};
const filterRowMessages = {
  filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
  showAll: "Tutte",
  rowsPerPage: "Righe per pagina",
  info: "Da {from} a {to} ({count} richieste)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "id", direction: "desc" }];

export default function TabellaEventi({refreshCount}) {
  // table data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [from, setFrom] = useState(moment().startOf('month'));
  const [to, setTo] = useState(moment().endOf('month'));

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);

  const updateFromServer = () => {
    setLoading(true);
    apiCalendario.getEventi(user.authToken, from, to)
      // row.actions is passed to the btn constructors which passes it to the callbacks
      .then((data) => setRows(data.map((row) => ({ ...row, actions: { id: row.id, department: row.department } }))))
      .then(() => setLoading(false)).catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Errore nel caricamento degli eventi",
          confirmButtonColor: "#3699ff",
          confirmButtonText: "Chiudi",
        });
        console.error(err);
    });
  };
  useEffect(() => {
    updateFromServer();
  }, [refreshCount]);

//   // edit page
//   const [showEditPage, setShowEditPage] = useState(false);
//   const [campioneForEdit, setCampioneForEdit] = useState(false);
//   // delete modal
//   const [showDeleteModal, setShowDeleteModal] = useState(false);

//   // bind history to views to allow deep-linking
//   const { location, push } = useHistory();
//   useEffect(() => {
//     if (!loading) {
//       if (location.hash && location.hash === "#/new") {
//         setCampioneForEdit(false);
//         setShowEditPage(true);
//         setShowDeleteModal(false);
//       } else if (location.hash && location.hash.startsWith("#/delete/")) {
//         const idForEdit = parseInt(location.hash.substring(9));
//         if (!isNaN(idForEdit)) {
//           const row = rows.find((r) => r.id == idForEdit);
//           if (row !== undefined) {
//             setCampioneForEdit(row);
//             setShowEditPage(false);
//             setShowDeleteModal(true);
//           }
//         }
//       } else if (location.hash && location.hash.startsWith("#/edit/")) {
//         const idForEdit = parseInt(location.hash.substring(7));
//         if (!isNaN(idForEdit)) {
//           const row = rows.find((r) => r.id == idForEdit);
//           if (row !== undefined) {
//             setCampioneForEdit(row);
//             setShowEditPage(true);
//             setShowDeleteModal(false);
//           }
//         }
//       } else {
//         setCampioneForEdit(false);
//         setShowEditPage(false);
//         setShowDeleteModal(false);
//       }
//     }
//     /*eslint-disable-next-line*/
//   }, [location.hash, loading]);

  // paging and sorting
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState(initialSorting);

  if (true) {
    return (
      <>
        {/* <DeleteModal
          show={showDeleteModal}
          name={campioneForEdit.name}
          hideCallback={() => {
            // setShowDeleteModal(false);
            push('#/');
          }}
          deleteCallback={() => {
            apiCampioni.remove(user.authToken, campioneForEdit.id).then(() => {
              // setShowDeleteModal(false);
              push('#/');
              updateFromServer();
            }
            );
          }}
        /> */}
        <Card className="card-custom gutter-b">
          <Card.Header className="border-0 py-5 row">
            <Toolbar
              // addCallback={() => {
              //   // setForEdit(false);
              //   // setShowEditPage(true);
              //   push('#/new');
              // }}
              from={from}
              setFrom={setFrom}
              to={to}
              setTo={setTo}
              className="float-right col-auto"
            />
          </Card.Header>
          <Card.Body className="pt-0">
            {!loading ? (
              <Grid rows={rows} columns={columns}>
                <ActionsColumn
                  isAdmin={role === roles.admin || role === roles.developer}
                //   editCallback={(id) => {
                //     // setCampioneForEdit(rows.find((r) => r.id === id));
                //     // setShowEditPage(true);
                //     push(`#/edit/${id}`);
                //   }}
                //   deleteCallback={(id) => {
                //     // setCampioneForEdit(rows.find((r) => r.id === id));
                //     // setShowDeleteModal(true);
                //     push(`#/delete/${id}`);
                //   }}
                />
                <DateFormattedColumn for={["startDate", "endDate"]} />
                <FilteringState />
                <IntegratedFiltering />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting />
                <Table
                  messages={tableMessages}
                  columnExtensions={columnExtensions}
                  tableComponent={TableComponent}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow
                  messages={filterRowMessages}
                  cellComponent={FilterCell}
                />
                <PagingPanel
                  messages={pagingPanelMessages}
                  pageSizes={pageSizes}
                />
              </Grid>
            ) : (
              <div>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={518} />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  } else
    return (<></>
    //   <EditNewCampione
    //     campione={campioneForEdit}
    //     hideEditPage={() => {
    //       // setShowEditPage(false);
    //       push('#/');
    //     }}
    //     triggerServerUpdate={updateFromServer}
    //   />
    );
};
