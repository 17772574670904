/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
/*eslint-disable-next-line*/
import { shallowEqual, useSelector } from "react-redux";
import { Chip, TextField, Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Skeleton } from "@material-ui/lab";
import * as PropTypes from "prop-types";
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    FilteringState,
    IntegratedFiltering,
    DataTypeProvider,
    RowDetailState,
} from "@devexpress/dx-react-grid";
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableFilterRow,
    TableRowDetail,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import SVG from "react-inlinesvg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBox,
    faBoxOpen,
    faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
/*eslint-disable-next-line*/
import { Card, Tooltip, OverlayTrigger, Button, Form, Modal } from "react-bootstrap";
import moment from "moment-timezone";
import { roles, icons, statusRichiesta, metodiPagamento, scontiMetodiPagamento} from "../../config/config";
//import * as apiCampioni from "../../api/campioni";
import * as apiRichiesteProdotti from "../../api/richiesteVendita";

function groupRichieste(list) {
    const map = new Map();
    list.forEach((item) => {
        const collection = map.get(item.idRichiesta);
        const {
            idRichiesta,
            idAgente,
            status,
            lastUpdate,
            dateCreated,
            agente,
            address,
            metodoPagamento,
            note,
            quantity,
            sconto,
            idProdotto,
            prodotto,
            division,
            image,
            price,
            cliente,
            clienteAddress,
            clienteProvincia,
            clienteComune,
            clienteCap,
            clientePartitaIva,
            clienteCodicePec,
            clienteReferente,
        } = item;
        if (!collection) {
            map.set(idRichiesta, {
                idRichiesta,
                idAgente,
                status: parseInt(status),
                lastUpdate,
                dateCreated,
                agente,
                address,
                cliente,
                clienteAddress,
                clienteProvincia,
                clienteComune,
                clienteCap,
                clientePartitaIva,
                clienteCodicePec,
                clienteReferente,
                metodoPagamento: parseInt(metodoPagamento),
                note,
                cart: [{
                    idProdotto,
                    name: prodotto,
                    quantity: parseInt(quantity),
                    sconto: parseFloat(sconto),
                    price: parseFloat(price),
                    division,
                    image,
                }],
            });
        } else {
            collection.cart.push({
                idProdotto,
                name: prodotto,
                quantity: parseInt(quantity),
                sconto: parseFloat(sconto),
                price: parseFloat(price),
                division,
                image,
            });
        }
    });
    for (let [idRichiesta, richiesta] of map) {
        map.set(idRichiesta, {
            ...richiesta,
            quantity: richiesta.cart.reduce(
                (q, item) => q + item.quantity,
                0
            ),
        });
    }
    return map;
}

// Useful sub-components
const TableComponent = ({ ...restProps }) => (
    <Table.Table
        {...restProps}
        className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
);

const getTableRow = (clickCallback) => { 
    return ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => clickCallback(restProps.tableRow.rowId)}
            style={{
                cursor: 'pointer',
            }}
        />
    );
};

const FilterCell = (props) => {
    return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
    column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const DateFormattedColumn = (props) => (
    <DataTypeProvider
        formatterComponent={({ value }) =>
            moment(value).format("DD MMMM YYYY, HH:mm")
        }
        {...props}
    />
);

const makeActionBtn = ({ name, disabled, tooltip, callback, variant, icon, value }) => {
    const btn = (
        <Button
            variant={variant}
            className={`mx-3 my-2`}
            onClick={!disabled ? () => callback(value) : null}
            disabled={disabled}
            title={name}
        >
            {typeof icon == "string" ? (
                <span className={`svg-icon svg-icon-md svg-icon-white`}>
                    {" "}
                    <SVG src={toAbsoluteUrl(icon)} />
                </span>
            ) : (
                <FontAwesomeIcon icon={icon} className={`svg-icon-white`} style={{ marginRight: "0.5rem" }} />
            )}
            <span className='text-white'>{name}</span>
            {/* {name} */}
        </Button>);
    if (!tooltip) return btn;
    return <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>{btn}</OverlayTrigger>
};

const StatoColumn = ({ value }) => {
    /*eslint-disable-next-line*/
    if (value == statusRichiesta.inAttesa)
        return (
            // in attesa
            <div className="pl-3">
                <Chip
                    // color="info"
                    variant="outlined"
                    label="In attesa"
                    icon={
                        <FontAwesomeIcon
                            icon={faBoxOpen}
                            className="svg-icon-info svg-icon-md"
                        />
                    }
                    style={{ borderColor: "var(--info)", color: "var(--info)" }}
                />
            </div>
        );
    /*eslint-disable-next-line*/
    if (value == statusRichiesta.approvato)
      return (
        // approvato
        <div className="pl-3">
          <Chip
            color="success"
            variant="outlined"
            label="Approvato"
            icon={icons.ok}
          />
        </div>
      );
    /*eslint-disable-next-line*/
    if (value == statusRichiesta.inevaso)
        return (
            // pronto
            <div className="pl-3">
                <Chip
                    color="warning"
                    variant="outlined"
                    label="Inevaso"
                    icon={
                        <FontAwesomeIcon
                            icon={faBox}
                            className="svg-icon-warning svg-icon-md"
                        />
                    }
                    style={{ borderColor: "var(--warning)" }}
                />
            </div>
        );
    /*eslint-disable-next-line*/
    if (value == statusRichiesta.evaso)
        return (
            // consegnato
            <div className="pl-3">
                <Chip
                    // color=""
                    variant="outlined"
                    label="Evaso"
                    icon={
                        <FontAwesomeIcon
                            icon={faSquareCheck}
                            className="svg-icon-primary svg-icon-md"
                        />
                    }
                    style={{ borderColor: "var(--primary)", color: "var(--primary)" }}
                />
            </div>
        );
    /*eslint-disable-next-line*/
    if (value == statusRichiesta.rifiutato)
        return (
            // cancellato
            <div className="pl-3">
                <Chip
                    // color="danger"
                    variant="outlined"
                    label="Rifiutato"
                    icon={
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                            {" "}
                            <SVG src={toAbsoluteUrl(icons.close)} />
                        </span>
                    }
                    style={{ borderColor: "var(--danger)", color: "var(--danger)" }}
                />
            </div>
        );
    return (<div className="pl-3"></div>);
};

export const StatoFormattedColumn = (props) => (
    <DataTypeProvider formatterComponent={StatoColumn} {...props} />
);

const RichiestaRowDetail = ({
    canApprove,
    canManage,
    isRowLoading,
    isItemLoading,
    isEditing,
    editedValue,
    updateStatusCallback,
    updateCartQuantityCallback,
    saveCartQuantityCallback,
    removeFromCartCallback,
    cancelEditingCallback,
}) => <TableRowDetail contentComponent={({ row }) => {
    const isDisabled = {
        // agente/mag -> permesso se in attesa | admin -> permesso se non già evaso
        edit: (!canApprove && row.status != statusRichiesta.inAttesa) || (canApprove && row.status == statusRichiesta.evaso),
        // agente -> permesso se in attesa | admin/mag -> permesso se non già evaso o rifiutato
        cancel: (!canManage && row.status != statusRichiesta.inAttesa) || (canManage && (row.status == statusRichiesta.evaso || row.status == statusRichiesta.rifiutato)),
        // admin -> permesso se non già evaso o approvato
        approve: !canApprove || row.status == statusRichiesta.evaso || row.status == statusRichiesta.approvato,
        // admin/mag -> permesso se approvato
        setInevaso: !canManage || row.status != statusRichiesta.approvato,
        // admin/mag -> permesso se approvato o inevaso
        setEvaso: !canManage || !(row.status == statusRichiesta.approvato || row.status == statusRichiesta.inevaso)
    };
    const shownQuantity = {}
    for (var item of row.cart) {
        shownQuantity[item.idProdotto] = isEditing(row.idRichiesta, item.idProdotto) ? editedValue(row.idRichiesta, item.idProdotto) : item.quantity;
    }
    return (<>
        <div className="card">
            {row.cart.map((item) => (
                <>
                    <div className="d-flex align-items-center justify-content-xl-between p-8">
                        <div className="d-flex flex-column mr-2 order-2 order-xl-1 ml-2">
                            {/*eslint-disable-next-line*/}
                            <span className="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
                                {item.name}
                                {isDisabled.edit ? null : <Button
                                    variant="light-success"
                                    className="btn-xs btn-icon ml-2"
                                    disabled={isItemLoading(row.idRichiesta, item.idProdotto)}
                                    onClick={() => removeFromCartCallback(row.idRichiesta, item.idProdotto)}
                                >
                                    <i className="ki ki-close icon-xs"></i>
                                </Button>}
                            </span>
                            { item.sconto
                                ? (<> <div className="d-flex align-items-center mt-2">
                                    Sconto: {item.sconto}%
                                </div> </>)
                                : null}
                            <div className="d-flex align-items-center mt-2">
                                <span className="mr-2 text-dark-75 font-size-3">
                                    {typeof item.price !== 'undefined' ? `${item.price}€` : '-€'}
                                </span>
                                <span className="text-muted mr-1"> &#215; </span>
                                <span className={"font-weight-bold mr-1 text-dark-75 font-size-3"}>
                                    {isEditing(row.idRichiesta, item.idProdotto)
                                        ? <>
                                            {/* TODO: FARE FORM */}
                                            {editedValue(row.idRichiesta, item.idProdotto) !== 1 ? `${editedValue(row.idRichiesta, item.idProdotto)} colli` : '1 collo'}
                                        </>
                                        : <> {shownQuantity[item.idProdotto]} </>}
                                </span>
                                <span className="text-muted mr-2">
                                    =
                                </span>
                                <span className="mr-2 text-dark-75 font-size-3">
                                    {typeof item.price !== 'undefined' 
                                        ? (item.sconto > 0 ? <>
                                            <span style={{textDecoration: "line-through"}}>{(shownQuantity[item.idProdotto] * item.price).toFixed(2)}€</span>
                                            <span className="ml-2">{((1 - item.sconto/100) * item.price * shownQuantity[item.idProdotto]).toFixed(2)}</span>
                                        </> : `${(shownQuantity[item.idProdotto] * item.price).toFixed(2)}€`)
                                        : '-€'}
                                </span>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                {isDisabled.edit ? null : <Button
                                    variant="light-success"
                                    className="btn-xs btn-icon"
                                    disabled={isItemLoading(row.idRichiesta, item.idProdotto)}
                                    onClick={() => updateCartQuantityCallback(row.idRichiesta, item.idProdotto, Math.max(1, shownQuantity[item.idProdotto] - 1))}
                                >
                                    <i className="ki ki-minus icon-xs"></i>
                                </Button>}
                                {isDisabled.edit ? null : <Button
                                    variant="light-success"
                                    className="btn-xs btn-icon ml-2"
                                    disabled={isItemLoading(row.idRichiesta, item.idProdotto)}
                                    onClick={() => updateCartQuantityCallback(row.idRichiesta, item.idProdotto, shownQuantity[item.idProdotto] + 1)}
                                >
                                    <i className="ki ki-plus icon-xs"></i>
                                </Button>}
                                {!isDisabled.edit && isEditing(row.idRichiesta, item.idProdotto)
                                    ? <Button
                                        variant="success"
                                        className="btn-xs btn-icon ml-2"
                                        disabled={isItemLoading(row.idRichiesta, item.idProdotto)}
                                        onClick={() => saveCartQuantityCallback(row.idRichiesta, item.idProdotto)}
                                    >
                                        <i className="ki ki-check icon-xs"></i>
                                    </Button>
                                    : null}
                                {!isDisabled.edit && isEditing(row.idRichiesta, item.idProdotto)
                                    ? <Button
                                        variant="light-danger"
                                        className="btn-xs btn-icon ml-2"
                                        disabled={isItemLoading(row.idRichiesta, item.idProdotto)}
                                        onClick={() => cancelEditingCallback(row.idRichiesta, item.idProdotto)}
                                    >
                                        <i className="ki ki-close icon-xs"></i>
                                    </Button>
                                    : null}
                            </div>
                        </div>
                        {typeof item.image !== "undefined" &&
                            item.image !== "" &&
                            item.image !== "0" ? ( // TODO: REMOVE THE 0 ?
                            <div className="symbol symbol-70 order-xl-2">
                                <img
                                    src={
                                        item.image.startsWith("/")
                                            ? process.env.REACT_APP_PUBLIC_URL + item.image
                                            : item.image
                                    }
                                    alt=""
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="separator separator-solid"></div>
                </>
            ))}
            <div className="p-8">
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Numero totale
                    </span>
                    <span className="font-weight-bolder text-primary text-right">
                        {row.cart.reduce(
                            (q, item) => q + shownQuantity[item.idProdotto],
                            0
                        )}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Valore totale
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {row.cart.reduce(
                            (sum, item) =>
                                typeof item.price !== 'undefined' ? sum + item.price * shownQuantity[item.idProdotto] : sum,
                            0
                        ).toFixed(2)}€
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-7">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Valore totale scontato
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {
                        // AG: Aggiunta iva 1.22 (22%) fissa sul prezzo finale in caso di sconto
                        scontiMetodiPagamento[row.metodoPagamento] > 0
                            ? <> 
                                <span style={{textDecoration: "line-through"}}>{row.cart.reduce((sum, item) => typeof item.price !== 'undefined' ? sum + (item.sconto ? 1 - item.sconto/100 : 1) * item.price * shownQuantity[item.idProdotto] : sum, 0).toFixed(2)}€</span>
                                <span> + Sconto 5%</span>
                                (item.sconto) 
                                ?<span className="ml-2">{((1 - scontiMetodiPagamento[row.metodoPagamento]) * row.cart.reduce((sum, item) => typeof item.price !== 'undefined' ? sum + (item.sconto ? 1 - item.sconto/100 : 1) * item.price * shownQuantity[item.idProdotto] : sum, 0) * 1.22).toFixed(2)}</span>
                                :<span className="ml-2">{((1 - scontiMetodiPagamento[row.metodoPagamento]) * row.cart.reduce((sum, item) => typeof item.price !== 'undefined' ? sum + (item.sconto ? 1 - item.sconto/100 : 1) * item.price * shownQuantity[item.idProdotto] : sum, 0) ).toFixed(2)}</span>
                                </>
                            : (item.sconto) 
                                ? (row.cart.reduce((sum, item) => typeof item.price !== 'undefined' ? sum + (item.sconto ? 1 - item.sconto/100 : 1) * item.price * shownQuantity[item.idProdotto] : sum, 0) * 1.22).toFixed(2)
                                : (row.cart.reduce((sum, item) => typeof item.price !== 'undefined' ? sum + (item.sconto ? 1 - item.sconto/100 : 1) * item.price * shownQuantity[item.idProdotto] : sum, 0)).toFixed(2)
                        }€
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Agente
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {row.agente}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Indirizzo Agente
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {row.address}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Cliente
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {row.cliente}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Indirizzo Cliente
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {[
                            !!row.clienteAddress ? row.clienteAddress : '',
                            (!!row.clienteComune || !!row.clienteProvincia) 
                                ? (!!row.clienteComune ? row.clienteComune : '') + (!!row.clienteProvincia ? ' ('+row.clienteProvincia+')': '') 
                                : '',
                            !!row.clienteCap ? row.clienteCap : ''
                        ].filter((s) => !!s).join(', ')}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Partita IVA
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {row.clientePartitaIva}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Codice SDI / PEC
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {row.clienteCodicePec}
                    </span>
                </div>
                {row.clienteReferente ? <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Referente consegna
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {row.clienteReferente}
                    </span>
                </div> : null}
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Metodo di pagamento
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                        {metodiPagamento[row.metodoPagamento]}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Note
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        {row.note}
                    </span>
                </div>

            </div>
            <div className="separator separator-solid"></div>

            <div>
                <div className="float-xl-right">
                    {makeActionBtn({
                        name: canApprove ? "Rifiuta" : "Annulla",
                        callback: (id) => updateStatusCallback(id, statusRichiesta.rifiutato),
                        disabled: isRowLoading(row.idRichiesta) || isDisabled.cancel,
                        variant: "danger",
                        icon: icons.close,
                        value: row.idRichiesta,
                    })}
                    {canManage ? makeActionBtn({
                        name: "Approva",
                        callback: (id) => updateStatusCallback(id, statusRichiesta.approvato),
                        disabled: isRowLoading(row.idRichiesta) || isDisabled.approve,
                        variant: "success",
                        icon: icons.ok,
                        value: row.idRichiesta,
                    }) : null}
                    {canManage
                        ? makeActionBtn({
                            name: "Pronto",
                            tooltip: "Pronto per ritiro/spedizione",
                            callback: (id) => updateStatusCallback(id, statusRichiesta.inevaso),
                            disabled: isRowLoading(row.idRichiesta) || isDisabled.setInevaso,
                            variant: "warning",
                            icon: faBox,
                            value: row.idRichiesta,
                        })
                        : null}
                    {canManage
                        ? makeActionBtn({
                            name: "Evaso",
                            callback: (id) => updateStatusCallback(id, statusRichiesta.evaso),
                            disabled: isRowLoading(row.idRichiesta) || isDisabled.setEvaso,
                            variant: "primary",
                            icon: faSquareCheck,
                            value: row.idRichiesta,
                        }) : null}
                </div>
            </div>
        </div>
    </>);
}} />;

// Columns definition
const prodottiColumns = [
    { name: "agente", title: "Agente" },
   // { name: "quantity", title: "Quantità" },
    { name: "status", title: "Stato" },
    { name: "lastUpdate", title: "Ultima Modifica" },
   // { name: "address", title: "Indirizzo Agente" },
    { name: "cliente", title: "Cliente" },
    { name: "dateCreated", title: "Data Creazione" },
   // { name: "clienteAddress", title: "Indirizzo Cliente" },
];
const columnExtensions = [
    { columnName: "agente", wordWrapEnabled: true },
   // { columnName: "quantity", width: "auto" },
    { columnName: "status", width: "auto" },
    { columnName: "lastUpdate", wordWrapEnabled: true },
   // { columnName: "address", wordWrapEnabled: true },
    { columnName: "cliente", wordWrapEnabled: true },
    { columnName: "dateCreated", wordWrapEnabled: true },

   // { columnName: "clienteAddress", wordWrapEnabled: true },
];

// Messages
const tableMessages = {
    noData: "Nessuna richiesta effettuata",
};
const filterRowMessages = {
    filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
    showAll: "Tutte",
    rowsPerPage: "Righe per pagina",
    info: "Da {from} a {to} ({count} richieste)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "lastUpdate", direction: "desc" }];

export const RichiesteProdotti = () => {
    // table data
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingRows, setLoadingRows] = useState({});
    const [loadingItems, setLoadingItems] = useState({});
    const [editingItems, setEditingItems] = useState({});
    const [_internalUpdateCount, _setInternalUpdateCount] = useState(0);

    // User redux
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const role = parseInt(user.idRole);

    // nascondi colonna "agente" se non admin/magazziniere
    const columns = (role === roles.admin || role === roles.developer || role ===  roles.magazziniere || role === roles.contabile)
        ? prodottiColumns 
        : prodottiColumns.slice(1);

    const updateFromServer = () => {
        setLoading(true);
        apiRichiesteProdotti
            .getAll(user.authToken, "prodotti")
            .then((data) => {
                const sortedData = data.sort((a, b) => {
                    return new Date(b.lastUpdate) - new Date(a.lastUpdate);
                });
                setRows(groupRichieste(sortedData))
            })
            .then(() => setLoading(false));
    };
    useEffect(() => {
        updateFromServer();
        /*eslint-disable-next-line*/
    }, [_internalUpdateCount]);

    const doUpdate = () => {
        _setInternalUpdateCount(_internalUpdateCount + 1);
    };

    const addLoadingRow = (id) => {
        const clone = { ...loadingRows };
        clone[id] = true;
        setLoadingRows(clone);
    };
    const removeLoadingRow = (id) => {
        const clone = { ...loadingRows };
        delete clone[id];
        setLoadingRows(clone);
    };

    const addLoadingItem = (id, idProdotto) => {
        const clone = { ...loadingItems };
        if (!(id in clone)) {
            //clone[id] = {...loadingItems[id]};
            clone[id] = {}
        }
        clone[id][idProdotto] = true
        setLoadingItems(clone);
    };
    const removeLoadingItem = (id, idProdotto) => {
        const clone = { ...loadingItems };
        if (id in clone) {
            if (idProdotto in clone[id]) {
                delete clone[id][idProdotto];
            }
            if (!clone[id]) {
                delete clone[id];
            }
        }
        setLoadingItems(clone);
    };

    const addEditingItem = (id, idProdotto, quantity) => {
        const clone = { ...editingItems };
        if (!(id in clone)) {
            //clone[id] = {...loadingItems[id]};
            clone[id] = {}
        }
        clone[id][idProdotto] = quantity
        setEditingItems(clone);
    };
    const removeEditingItem = (id, idProdotto) => {
        const clone = { ...editingItems };
        delete clone[id][idProdotto];
        if (!clone[id]) {
            delete clone[id];
        }
        setEditingItems(clone);
    };

    // paging and sorting
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [sorting, setSorting] = useState(initialSorting);
    const [expandedRows, setExpandedRows] = useState([]);

    /*eslint-disable-next-line*/
    return (
        <>
            <Card className="card-custom gutter-b">
                <Card.Header className="border-0 py-5 row">
                    <Card.Title className="float-left col-auto">
                        <div>
                            <h3 className="font-weight-bolder text-dark">
                                Richieste di Prodotti
                            </h3>
                            <h6 className="font-weight-light text-dark mobile-only">
                                Scorri a destra la tabella per altre informazioni
                            </h6>
                        </div>
                    </Card.Title>
                </Card.Header>

                <Card.Body className="pt-0">
                    {!loading ? (
                        <Grid rows={[...rows.values()].map(row => ({ ...row }))} columns={columns}>
                            <DateFormattedColumn for={["lastUpdate", "dateCreated"]} />
                            <StatoFormattedColumn for={["status"]} />
                            <RowDetailState 
                                expandedRowIds={expandedRows} 
                                onExpandedRowIdsChange={setExpandedRows} />
                            <FilteringState />
                            <IntegratedFiltering />
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={setCurrentPage}
                                pageSize={pageSize}
                                onPageSizeChange={setPageSize}
                            />
                            <IntegratedPaging />
                            <SortingState sorting={sorting} onSortingChange={setSorting} />
                            <IntegratedSorting />
                            <Table
                                messages={tableMessages}
                                tableComponent={TableComponent}
                                columnExtensions={columnExtensions}
                                rowComponent={getTableRow(
                                    (id) => setExpandedRows(expandedRows.includes(id) 
                                        ? [...expandedRows].filter((v) => v != id) 
                                        : [...expandedRows, id])
                                )}
                            />
                            <TableHeaderRow showSortingControls />
                            <TableFilterRow
                                messages={filterRowMessages}
                                cellComponent={FilterCell} 
                            />
                            <RichiestaRowDetail
                                /*eslint-disable-next-line*/
                                canApprove={role === roles.admin || role === roles.developer || role === roles.magazziniere}
                                canManage={role === roles.admin || role === roles.developer || role === roles.magazziniere}
                                isRowLoading={(id) => id in loadingRows}
                                isItemLoading={(id, idProdotto) => id in loadingItems && idProdotto in loadingItems[id]}
                                isEditing={(id, idProdotto) => id in editingItems && idProdotto in editingItems[id]}
                                editedValue={(id, idProdotto) => editingItems[id][idProdotto]}
                                updateStatusCallback={(id, status) => {
                                    addLoadingRow(id);
                                    apiRichiesteProdotti.update(user.authToken, id, status).then(() => {
                                        removeLoadingRow(id);
                                        doUpdate();
                                    });
                                }}
                                updateCartQuantityCallback={(id, idProdotto, quantity) => {
                                    addEditingItem(id, idProdotto, quantity);
                                }}
                                saveCartQuantityCallback={(id, idProdotto) => {
                                    addLoadingItem(id, idProdotto);
                                    let newQuantity = editingItems[id][idProdotto];
                                    apiRichiesteProdotti.updateCartQuantity(user.authToken, id, idProdotto, newQuantity).then(() => {
                                        removeLoadingItem(id, idProdotto);
                                        doUpdate();
                                        removeEditingItem(id, idProdotto);
                                    });
                                }}
                                cancelEditingCallback={(id, idProdotto) => {
                                    removeEditingItem(id, idProdotto);
                                }}
                                removeFromCartCallback={(id, idProdotto) => {
                                    addLoadingItem(id, idProdotto);
                                    apiRichiesteProdotti.removeFromCart(user.authToken, id, idProdotto).then(() => {
                                        removeLoadingItem(id, idProdotto);
                                        doUpdate();
                                    });
                                }}
                            />
                            <PagingPanel
                                messages={pagingPanelMessages}
                                pageSizes={pageSizes}
                            />
                        </Grid>
                    ) : (
                        <div>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="rect" height={518} />
                        </div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};
