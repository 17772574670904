import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment-timezone";

import * as apiCalendario from "../../api/calendario";

export const AddRichiesta = ({ type, setType, show, setShow, doUpdate, user }) => {
  const [loading, setLoading] = useState(false);

  const [initialValues] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    startTime: "08:30",
    endDate: "",
    endTime: "17:00",
  });

  const validationSchema = yup.object({
    startDate: yup.date().required("Inserire la data di inizio " + type),
    startTime: yup.string().required("Inserire orario inizio " + type),
    endDate: yup.date("Inserire una data valida").test(
      "required when",
      "Inserire una data di fine " + type,
      function (value) {
        let startDate = this.options.parent.startDate;
        return !!value || !!startDate;
      }
    ),
    endTime: yup.string().required("Inserire orario fine " + type),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      let data = {
        startDate: values.startDate,
        startTime: values.startTime,
        endDate: !!values.endDate ? values.endDate : values.startDate,
        endTime: values.endTime,
        type: type,
      };
      setLoading(true);
      apiCalendario.addEvento({ accessToken: user.authToken, ...data })
        .then(() => {
          setShow(false);
          doUpdate();
        })
        .catch((e) => {
          setStatus(e.message);
          setSubmitting(false);
        })
        .finally(() => setLoading(false));
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setType("");
      }}
    >
      <Modal.Header className="border-0">
        <Modal.Title>Richiedi {type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">Data inizio {type}</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                className={getInputClasses("startDate")}
                {...formik.getFieldProps("startDate")}
              />
              {formik.touched["startDate"] && formik.errors["startDate"] ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors["startDate"]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">Ora inizio {type}</Form.Label>
              <Form.Control
                type="time"
                name="startTime"
                min="07:00"
                max="19:00"
                className={getInputClasses("startTime")}
                {...formik.getFieldProps("startTime")}
              />
              {formik.touched["startTime"] && formik.errors["startTime"] ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors["startTime"]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">Data fine {type}</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                min={moment(formik.values.startDate).format("YYYY-MM-DD")}
                className={getInputClasses("endDate")}
                {...formik.getFieldProps("endDate")}
              />
              {formik.touched["endDate"] && formik.errors["endDate"] ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors["endDate"]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">Orario fine {type}</Form.Label>
              <Form.Control
                type="time"
                name="endTime"
                min="07:00"
                max="19:00"
                className={getInputClasses("endTime")}
                {...formik.getFieldProps("endTime")}
              />
              {formik.touched["endTime"] && formik.errors["endTime"] ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors["endTime"]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 d-block">
        <Button
          onClick={() => {
            setShow(false);
            setType("");
            formik.resetForm();
          }}
          className="float-right"
          variant="danger"
        >
          Annulla
        </Button>
        <Button className="float-right" variant="primary" type="submit" disabled={loading} onClick={formik.handleSubmit}>
          Richiedi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
