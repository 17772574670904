import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as yup from "yup";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useSelector, shallowEqual } from "react-redux";
import * as apiUtenti from "../../../../api/users";
import * as apiMedici from "../../../../api/medici";
import { icons, roles, isAgente } from "../../../../config/config";

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const validationSchema = yup.object({
  firstname: yup
    .string("Inserire un nome valido")
    .required("Inserire un nome valido")
    .min(3, "Minimo tre caratteri")
    .max(50, "Massimo cinquanta caratteri"),
  lastname: yup
    .string("Inserire un cognome valido")
    .required("Inserire un cognome valido")
    .min(3, "Minimo tre caratteri")
    .max(50, "Massimo cinquanta caratteri"),
  address: yup
    .string("Inserire un indirizzo valido")
    .required("Inserire un indirizzo valido")
    .min(3, "Minimo tre caratteri")
    .max(255, "Massimo 255 caratteri"),
  specializzazione: yup
    .string("Inserire una specializzazione valida")
    .required("Inserire una specializzazione valida")
    .min(3, "Minimo tre caratteri")
    .max(100, "Massimo 100 caratteri"),
  email: yup
    .string()
    .email("Inserire un'email valida")
    .required("Inserire un'email valida")
    .min(3, "Minimo 3 caratteri")
    .max(50, "Massimo 50 caratteri"),
  phone: yup
    .string()
    .required("Inserire un numero di telefono valido")
    .min(9, "Minimo 9 cifre")
    .max(15, "Massimo 15 cifre")
    .matches(phoneRegExp, "Solo numeri"),
  idAgente: yup
    .number("Inserire un agente valido")
    .positive("Selezionare un agente")
    .required("Seleziona un agente"),
});

const actionsMessages = {
  new: "Crea",
  edit: "Modifica",
  reset: "Reset",
};

const formFields = {
  firstname: {
    label: "Nome",
    placeholder: "Nome...",
  },
  lastname: {
    label: "Cognome",
    placeholder: "Cognome...",
  },
  address: {
    label: "Indirizzo",
    placeholder: "Indirizzo completo...",
  },
  specializzazione: {
    label: "Specializzazione",
    placeholder: "Specializzazione...",
  },
  email: {
    label: "Email",
    placeholder: "Email medico...",
  },
  phone: {
    label: "Telefono",
    placeholder: "Telefono medico...",
  },
  idAgente: {
    label: "Selezionare l'agente",
    placeholder: "Agente...",
  },
};

const Toolbar = ({
  submitCallback,
  closeCallback,
  resetCallback,
  loading,
  alreadyExisting,
  ...props
}) => {
  return (
    <div {...props}>
      <Button
        disabled={loading}
        onClick={submitCallback}
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-light-primary">
          <SVG src={toAbsoluteUrl(icons.ok)} />
        </span>
        {alreadyExisting ? actionsMessages.edit : actionsMessages.new}
      </Button>
      <Button
        disabled={loading}
        onClick={resetCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.reset)} />
        </span>
        {actionsMessages.reset}
      </Button>
      <Button
        onClick={closeCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.go_back)} />
        </span>
        Medici
      </Button>
    </div>
  );
};

export const EditNewMedico = (props) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [agenti, setAgenti] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialState = {
    id: false,
    firstname: "",
    lastname: "",
    idAgente: parseInt(user.idRole) === roles.admin ? -1 : user.id,
    email: "",
    phone: "",
    address: "",
    specializzazione: "",
  };
  const alreadyExisting = props.medico !== false;
  if (alreadyExisting) {
    Object.assign(initialState, props.medico);
  }

  useEffect(() => {
    if (parseInt(user.idRole) === roles.admin) {
      apiUtenti
        .getAll(props.token)
        .then((data) =>
          setAgenti(
            data
              .filter((user) => isAgente(parseInt(user.role)))
              .map((agente) => ({ value: agente.id, label: agente.fullname }))
          )
        );
    }
    /*eslint-disable-next-line*/
  }, []);

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const result = {
        ...values,
        idMedico: alreadyExisting ? initialState.id : "",
      };
      apiMedici
        .add(props.token, result)
        .then(() => setLoading(false))
        .then(() => props.triggerServerUpdate())
        .then(() => props.hideEditPage());
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  return (
    <Card className="card-custom gutter-b">
      <Card.Header className="border-0 py-5 row">
        <Card.Title className="float-left col-auto">
          <h3 className="font-weight-bolder text-dark">
            {alreadyExisting
              ? actionsMessages.edit + " medico"
              : actionsMessages.new + " medico"}
          </h3>
        </Card.Title>
        <Toolbar
          submitCallback={formik.handleSubmit}
          resetCallback={formik.handleReset}
          closeCallback={props.hideEditPage}
          loading={loading}
          alreadyExisting={alreadyExisting}
          className="float-right col-auto"
        />
      </Card.Header>
      <Card.Body className="pt-0">
        <Form className="row">
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["firstname"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              placeholder={formFields["firstname"].placeholder}
              className={getInputClasses("firstname")}
              {...formik.getFieldProps("firstname")}
            />
            {formik.touched["firstname"] && formik.errors["firstname"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors["firstname"]}
                </div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["lastname"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              placeholder={formFields["lastname"].placeholder}
              className={getInputClasses("lastname")}
              {...formik.getFieldProps("lastname")}
            />
            {formik.touched["lastname"] && formik.errors["lastname"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["lastname"]}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["email"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder={formFields["email"].placeholder}
              className={getInputClasses("email")}
              {...formik.getFieldProps("email")}
            />
            {formik.touched["email"] && formik.errors["email"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["email"]}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["phone"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="phone"
              placeholder={formFields["phone"].placeholder}
              className={getInputClasses("phone")}
              {...formik.getFieldProps("phone")}
            />
            {formik.touched["phone"] && formik.errors["phone"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["phone"]}</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["address"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder={formFields["address"].placeholder}
              className={getInputClasses("address")}
              {...formik.getFieldProps("address")}
            />
            {formik.touched["address"] && formik.errors["address"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["address"]}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["specializzazione"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="specializzazione"
              placeholder={formFields["specializzazione"].placeholder}
              className={getInputClasses("specializzazione")}
              {...formik.getFieldProps("specializzazione")}
            />
            {formik.touched["specializzazione"] && formik.errors["specializzazione"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["specializzazione"]}</div>
              </div>
            ) : null}
          </Form.Group>

          {parseInt(user.idRole) === roles.admin ? (
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">
                {formFields["idAgente"].label}
              </Form.Label>
              <select
                type="text"
                name="idAgente"
                className={`form-control ${getInputClasses("idAgente")}`}
                {...formik.getFieldProps("idAgente")}
              >
                <option value={0}>{formFields["idAgente"].placeholder}</option>
                {agenti.map((e, key) => (
                  <option key={key} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </select>
              {formik.touched["idAgente"] && formik.errors["idAgente"] ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors["idAgente"]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          ) : null}
        </Form>
      </Card.Body>
    </Card>
  );
};
