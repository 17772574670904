
/* eslint-disable eqeqeq */
import React, { useState, createRef } from "react";
import Calendar from "../modules/Calendario/Calendar";
import TabellaRichieste from "../modules/Calendario/TabellaRichieste";
import TabellaEventi from "../modules/Calendario/TabellaEventi";

import { Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarPlus,
  faPlane,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { roles, isAgente } from "../config/config";
import { AddEvento } from "../modules/Calendario/AddEvento";
import { AddRichiesta } from "../modules/Calendario/AddRichiesta";

const IconButton = (icon) => <FontAwesomeIcon icon={icon} />;
const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

export const WrapperCalendario = (componentName, showAddEventoBtn, showAddRichiestaBtn) => {
  const [type, setType] = useState("");
  const [showRichiesta, setShowRichiesta] = useState(false);
  const [showEvento, setShowEvento] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const role = parseInt(user.idRole);

  const doUpdate = () => {
    setRefreshCounter(refreshCounter + 1);
  }

  const makeButton = (text, classname, icon, variant, type) => (
    <OverlayTrigger placement="top" overlay={renderTooltip(text)}>
      <Button
        onClick={
          type === "event"
            ? () => setShowEvento(true)
            : () => {
                setType(type);
                setShowRichiesta(true);
              }
        }
        variant={variant}
        className={classname}
        size="sm"
      >
        {IconButton(icon)}
      </Button>
    </OverlayTrigger>
  );

  return (
    <Card className="card-custom gutter-b">
      <Card.Header className="border-0 p-5 d-block">
        {showAddEventoBtn ? makeButton(
          "Nuovo evento",
          "float-right ml",
          faCalendarPlus,
          "success",
          "event"
        ) : null}
        {showAddRichiestaBtn //&& isAgente(role)
          ? makeButton(
              "Richiedi permesso",
              "float-right mx-3",
              faClock,
              "warning",
              "permesso"
            )
          : null}
        {showAddRichiestaBtn //&& isAgente(role)
          ? makeButton(
              "Richiedi ferie",
              "float-right mr",
              faPlane,
              "danger",
              "ferie"
            )
          : null}
      </Card.Header>
      <Card.Body className="p-5">
        <AddRichiesta
          type={type}
          setType={setType}
          user={user}
          show={showRichiesta}
          setShow={setShowRichiesta}
          doUpdate={doUpdate}
        />
        <AddEvento
          user={user}
          show={showEvento}
          setShow={setShowEvento}
          doUpdate={doUpdate}
        />
        { componentName === "calendar" ? 
        <Calendar
          refreshCounter={refreshCounter}
        /> : null}
        { componentName === "tabellaEventi" ? 
        <TabellaEventi
          refreshCounter={refreshCounter}
        /> : null}
        { componentName === "tabellaRichieste" ? 
        <TabellaRichieste
          refreshCounter={refreshCounter}
        /> : null}
      </Card.Body>
    </Card>
  );
};

export const Calendario = {
  Calendario: () => WrapperCalendario("calendar", true, true),
  TabellaEventi: () => WrapperCalendario("tabellaEventi", true, false),
  TabellaRichieste: () => WrapperCalendario("tabellaRichieste", false, true),
};
