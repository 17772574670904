import React, { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { roles } from "../../../../app/config/config";

export function Brand() {
  const uiService = useHtmlClassService();

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
      asideStyle:
        role === roles.agenteRoypet ? "brand-roypet" : "brand-roydermal",
      logo:
        role === roles.agenteRoypet
          ? toAbsoluteUrl("/media/logos/roypet-logo-bianco.png")
          : toAbsoluteUrl("/media/logos/roydermal-logo-bianco.png"),
      target: role === roles.agenteRoypet ? "https://www.roypet.it/" : "https://roydermal.it/",
    };
    /*eslint-disable-next-line*/
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`${layoutProps.asideStyle} brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <a href={layoutProps.target} target={"_blank"} className="brand-logo">
          <img
            alt="logo"
            style={{ maxWidth: "110px" }}
            src={layoutProps.logo}
          />
        </a>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-double-left.svg"
                  )}
                />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
