import {post} from "./base";

export async function getAll(accessToken) {
  const result = await post("servizi/GetMedici", {accessToken});
  return result.map((u) => ({
    ...u,
    fullname: u.firstname + " " + u.lastname,
  }));
  // "idMedico", "firstname", "lastname", "dateCreated", "idAgente", "email", "phone" ("agente" if admin)
}
export async function add(accessToken, data) {
  // data should contain 'idMedico', 'firstname', 'lastname', 'idAgente', 'email', 'phone'
  await post("servizi/AddMedico", {jsonBody: {...data, accessToken}});
  console.log(`Medico ${data.idMedico} added`);
}

export async function remove(accessToken, idMedico) {
  await post("servizi/DeleteMedico", {jsonBody: {accessToken, idMedico}});
  console.log(`Medico ${idMedico} deleted`);
}
