import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { InputPic } from "../../../../_metronic/_partials/components/utils/InputPic";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, shallowEqual } from "react-redux";
import { add as addCampione } from "../../../api/campioni";
import { icons } from "../../../config/config";

const validationSchema = yup.object({
  name: yup
    .string("Inserire un nome valido")
    .required("Inserire un nome valido")
    .min(3, "Minimo tre caratteri")
    .max(50, "Massimo cinquanta caratteri"),
  description: yup
    .string("Inserire una descrizione valida")
    .notRequired()
    .min(3, "Minimo tre caratteri")
    .max(500, "Massimo cento caratteri"),
  division: yup
    .number("Selezionare un'azienda")
    .positive("Selezionare un'azienda")
    .required("Selezionare un'azienda"),
  quantity: yup
    .number("Inserire una quantità valida")
    .required("Inserire una quantità valida")
    .positive("Inserire una quantità valida"),
  cost: yup
    .number("Inserire un costo valido")
    .required("Inserire un costo valido")
    .positive("Inserire un costo valido"),
});

const actionsMessages = {
  new: "Crea",
  edit: "Modifica",
  removeImage: "Rimuovi Immagine",
  reset: "Reset",
};

const formFields = {
  name: {
    label: "Nome campione",
    placeholder: "Nome campione...",
  },
  description: {
    label: "Descrizione campione",
    placeholder: "Descrizione...",
    rows: 3,
  },
  division: {
    label: "Selezionare azienda",
    placeholder: "Azienda...",
  },
  quantity: {
    label: "Quantità per collo",
    placeholder: "Quantità...",
  },
  cost: {
    label: "Costo per collo (€)",
    placeholder: "Costo...",
  },
  image: {
    label: "Immagine prodotto",
  },
};

const Toolbar = ({
  submitCallback,
  resetImmagineCallback,
  resetCallback,
  closeCallback,
  loading,
  alreadyExisting,
  ...props
}) => {
  return (
    <div {...props}>
      <Button
        disabled={loading}
        onClick={submitCallback}
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-light-primary">
          <SVG src={toAbsoluteUrl(icons.ok)} />
        </span>
        {alreadyExisting ? actionsMessages.edit : actionsMessages.new}
      </Button>
      {!resetImmagineCallback ? null : (
        <Button
          disabled={loading}
          onClick={resetImmagineCallback}
          variant="danger"
          className="float-right my-1 mx-1"
        >
          <span className="svg-icon svg-icon-md svg-icon-light-primary">
            <SVG src={toAbsoluteUrl(icons.close)} />
          </span>
          {actionsMessages.removeImage}
        </Button>
      )}
      <Button
        disabled={loading}
        onClick={resetCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.reset)} />
        </span>
        {actionsMessages.reset}
      </Button>
      <Button
        onClick={closeCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.go_back)} />
        </span>
        Campioni
      </Button>
    </div>
  );
};

export const EditNewCampione = (props) => {
  const [loading, setLoading] = useState(false);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);

  var initialState = {
    id: false,
    name: "",
    description: "",
    division: 0,
    quantity: "",
    cost: "",
    image: false,
  };
  const alreadyExisting = props.campione !== false;
  if (alreadyExisting) {
    Object.assign(initialState, props.campione);
  }
  const [name, setName] = useState(initialState.name);
  const [description, setDescription] = useState(initialState.description);
  const [division, setDivision] = useState(initialState.division);
  const [quantity, setQuantity] = useState(initialState.quantity);
  const [cost, setCost] = useState(initialState.price);
  const [image, setImage] = useState(initialState.image);

  const handleReset = () => {
    setName(initialState.name);
    setImage(initialState.image);
    setDescription(initialState.description);
    setDivision(initialState.division);
    setQuantity(initialState.quantity);
    setCost(initialState.price);
    formik.handleReset();
  };

  const formik = useFormik({
    initialValues: {
      name: name,
      description: description,
      division: division,
      quantity: quantity,
      cost: cost,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let result = new FormData();
      result.append("id", alreadyExisting ? initialState.id : "");
      result.append("name", values.name);
      result.append("azienda", values.division);
      result.append("descrizione", values.description);
      result.append("quantity", parseInt(values.quantity));
      result.append("price", parseFloat(values.cost));
      result.append("accessToken", user.authToken);
      if (image !== false) result.append("image", image);

      addCampione(result)
        .then(() => {
          setLoading(false);
          props.triggerServerUpdate();
          props.hideEditPage();
        })
        .catch((e) => {
          setSubmitting(false);
          setLoading(false);
          setStatus(e.message);
        });
    },
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  return (
    <Card className="card-custom gutter-b">
      <Card.Header className="border-0 py-5 row">
        <Card.Title className="float-left col-auto">
          <h3 className="font-weight-bolder text-dark">
            {alreadyExisting
              ? actionsMessages.edit + " campione"
              : actionsMessages.new + " campione"}
          </h3>
        </Card.Title>
        <Toolbar
          submitCallback={formik.handleSubmit}
          resetImmagineCallback={!image ? null : () => setImage(false)}
          resetCallback={handleReset}
          closeCallback={props.hideEditPage}
          loading={loading}
          alreadyExisting={alreadyExisting}
          className="float-right col-auto"
        />
      </Card.Header>
      <Card.Body className="pt-0">
        <Form className="row">
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">{formFields.name.label}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder={formFields.name.placeholder}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={getInputClasses("name")}
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields.quantity.label}
            </Form.Label>
            <Form.Control
              type="number"
              name="quantity"
              placeholder={formFields.quantity.placeholder}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className={getInputClasses("quantity")}
              {...formik.getFieldProps("quantity")}
            />
            {formik.touched.quantity && formik.errors.quantity ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.quantity}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">{formFields.cost.label}</Form.Label>
            <Form.Control
              type="number"
              name="cost"
              placeholder={formFields.cost.placeholder}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              className={getInputClasses("cost")}
              {...formik.getFieldProps("cost")}
            />
            {formik.touched.cost && formik.errors.cost ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.cost}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields.division.label}
            </Form.Label>
            <select
              value={division}
              onChange={(e) =>
                e !== null ? setDivision(e.target.value) : null
              }
              name="division"
              className={`form-control ${getInputClasses("division")}`}
              {...formik.getFieldProps("division")}
            >
              <option value={0}>Seleziona azienda</option>
              <option value={1}>Roypet</option>
              <option value={2}>Roydermal</option>
            </select>
            {formik.touched.division && formik.errors.division ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.division}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">{formFields.image.label}</Form.Label>
            <InputPic
              name="productPic"
              id="productPic"
              pic={
                typeof image == "string"
                  ? { name: image.split("/").slice(-1) }
                  : image
              }
              setPic={setImage}
            />
          </Form.Group>
          <Form.Group className="col-12">
            <Form.Label className="col-12">
              {formFields.description.label}
            </Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              placeholder={formFields.description.placeholder}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={formFields.description.rows}
              style={{ resize: "none" }}
              className={getInputClasses("description")}
              {...formik.getFieldProps("description")}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            ) : null}
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
