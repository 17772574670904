import {post} from "./base";

export async function getAll(accessToken) {
  const result = await post("prodotti/GetClienti", {accessToken});
  return result.map((u) => ({
    ...u,
  }));
  // "idCliente", "firstname", "lastname", "dateCreated", "idAgente", "email", "phone" ("agente" if admin)
}
export async function add(accessToken, data) {
  // data should contain 'idCliente', 'firstname', 'lastname', 'idAgente', 'email', 'phone'
  await post("prodotti/AddCliente", {jsonBody: {...data, accessToken}});
  console.log(`Cliente ${data.idCliente} added`);
}

export async function remove(accessToken, idCliente) {
  await post("prodotti/DeleteCliente", {jsonBody: {accessToken, idCliente}});
  console.log(`Cliente ${idCliente} deleted`);
}
