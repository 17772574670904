import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { Campioni } from "./pages/Campioni/Campioni";
import { Vendita } from "./pages/Vendita/Vendita";
import { File } from "./pages/File/File";
import { Medici } from "./modules/Admin/pages/medici/Medici";
import { Clienti } from "./modules/Admin/pages/clienti/Clienti";
import AdminPage from "./modules/Admin/pages/AdminPage";
import { Calendario } from "./pages/Calendario";

//const AdminPage = lazy(() => import("./modules/Admin/pages/AdminPage"));

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          /*<Redirect exact from="/" to="/dashboard" />*/
          <Redirect exact from="/" to="/file" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/admin" component={AdminPage} />
        <Route path="/medici" component={Medici} />
        <Route path="/file" component={File} />
        <Route path="/calendario" component={Calendario.Calendario} />
        <Route path="/agenda" component={Calendario.TabellaEventi} />
        <Route path="/richiesta-ferie-permessi" component={Calendario.TabellaRichieste} />
        <Route path="/richiesta-campioni" component={Campioni.ShopCampioni} />
        <Route path="/gestione-campioni" component={Campioni.TabellaCampioni} />
        <Route path="/richiesta-materiale" component={Campioni.ShopMateriale} />
        <Route
          path="/gestione-materiale"
          component={Campioni.TabellaMateriale}
        />
        <Route path="/servizi" component={Campioni.Servizi} />
        <Route
          path="/status-campionatura-servizi"
          component={Campioni.Richieste}
        />
        <Route path="/richiesta-prodotti" component={Vendita.ShopProdotti} />
        <Route path="/gestione-prodotti" component={Vendita.TabellaProdotti} />
        <Route path="/status-vendita" component={Vendita.RichiesteProdotti} />
        <Route path="/clienti" component={Clienti} />

        <Route path="/user-profile" component={UserProfilepage} />

        <Redirect to="error/404" />

        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
      </Switch>
    </Suspense>
  );
}
