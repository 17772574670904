import React from "react";
import { Form } from "react-bootstrap";

//USA PIC COME PROPS PER COLLEGARE LO STATO DELL'IMMAGINE NEL FILE PRINCIPALE ES:
//pic = {*nome stato utilizzato*} setPic = {*nome setter stato*} id={*name campo*} name={*nome campo*} id={"ricettaMedica"} name={"ricettaMedica"}

export const InputPic = (props) => (
  <div className="custom-file">
    <Form.File
      type="file"
      accept="image/jpeg, image/png, image/jpg"
      className="custom-file-input"
      name={props.name}
      id={props.id}
      style={{ visibility: "hidden" }}
      onChange={(e) => props.setPic(e.target.files[0])}
    />
    <label
      className={`col-12 custom-file-label ${!props.pic ? "text-muted" : null}`}
      htmlFor={props.id}
    >
      {props.pic ? props.pic.name : "Seleziona immagine (jpg, jpeg, png)"}
    </label>
  </div>
);
