import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
//import { Form } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
//import { Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import * as PropTypes from "prop-types";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger, Button, Modal } from "react-bootstrap";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { roles, icons } from "../../../../config/config";
import * as apiClienti from "../../../../api/clienti";
import { EditNewCliente } from "./EditNewCliente";

const TableComponent = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className="table table-hover table-head-custom table-vertical-center overflow-hidden"
  />
);

const TabellaToolbar = ({ addCallback, ...props }) => {
  return (
    <div {...props}>
      <Button onClick={addCallback} className="float-right">
        Aggiungi cliente
      </Button>
    </div>
  );
};

const DateFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => moment(value).format("DD MMMM YYYY")}
    {...props}
  />
);

const FilterCell = (props) => {
  const { column } = props;
  if (column.name === "actions") {
    const ActionsFilterCell = ({ filter, onFilter }) => (
      <th style={{ fontWeight: "normal" }}>
        <input disabled style={{ visibility: "hidden" }} />
      </th>
    );
    ActionsFilterCell.propTypes = {
      filter: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
      onFilter: PropTypes.func.isRequired,
    };
    ActionsFilterCell.defaultProps = {
      filter: null,
    };
    return <ActionsFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const ActionsColumn = ({ editCallback, deleteCallback }) => (
  <DataTypeProvider
    for={["actions"]}
    formatterComponent={({ value }) => (
      <>
        <OverlayTrigger
          overlay={<Tooltip id="products-edit-tooltip">Modifica</Tooltip>}
        >
          {/*eslint-disable-next-line*/}
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => editCallback(value)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(icons.edit)} />
            </span>
          </a>
        </OverlayTrigger>

        <> </>
        <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">Elimina</Tooltip>}
        >
          {/*eslint-disable-next-line*/}
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => deleteCallback(value)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl(icons.delete)} />
            </span>
          </a>
        </OverlayTrigger>
      </>
    )}
  />
);

const DeleteModal = ({ name, show, hideCallback, deleteCallback }) => (
  <Modal show={show} onHide={hideCallback} className="card-custom gutter-b">
    <Modal.Header className="border-0 py-5 row">
      <Modal.Title>
        <h3 className="font-weight-bolder text-dark">Elimina cliente</h3>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-4">
      Sei sicuro di voler eliminare definitivamente "{name}"
    </Modal.Body>
    <Modal.Footer className="border-0 py-5 row">
      <Button onClick={deleteCallback} variant="danger" className="float-right">
        Elimina
      </Button>
      <Button
        onClick={hideCallback}
        variant="secondary"
        className="float-right"
      >
        Annulla
      </Button>
    </Modal.Footer>
  </Modal>
);

// Columns definition
const clientiColumns = [
  { name: "fullname", title: "Rag. sociale" },
  { name: "agente", title: "Agente" },
  { name: "email", title: "Email" },
  { name: "phone", title: "Telefono" },
  { name: "provincia", title: "Provincia" },
  { name: "comune", title: "Comune" },
  { name: "address", title: "Indirizzo" },
  { name: "cap", title: "CAP" },
  { name: "partitaiva", title: "Partita IVA" },
  { name: "codicepec", title: "SDI/PEC" },
  { name: "referente", title: "Referente" },
  { name: "dateCreated", title: "Creato il" },
  { name: "actions", title: "Azioni" },
];

// Messages
const tableMessages = {
  noData: "Nessun cliente disponibile",
};
const filterRowMessages = {
  filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
  showAll: "Tutte",
  rowsPerPage: "Righe per pagina",
  info: "Da {from} a {to} ({count} clienti)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "fullname", direction: "desc" }];

export const Clienti = () => {
  // table data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [_internalUpdateCount, _setInternalUpdateCount] = useState(0);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);

  // nascondi colonna "agente" se non admin/magazziniere
  const columns = (role === roles.admin || role ===  roles.magazziniere)
  ? clientiColumns 
  : [clientiColumns[0], ...clientiColumns.slice(2)];

  const updateFromServer = () => {
    setLoading(true);
    apiClienti
      .getAll(user.authToken)
      // row.actions is passed to the btn constructors which passes it to the callbacks
      .then((data) => {
        setRows(data.map((row) => ({ ...row, actions: row.id })));
      })
      .then(() => setLoading(false));
  };
  const triggerServerUpdate = () => _setInternalUpdateCount(_internalUpdateCount + 1);
  useEffect(() => {
    updateFromServer();
    /*eslint-disable-next-line*/
  }, [_internalUpdateCount]);

  // edit page
  const [showEditPage, setShowEditPage] = useState(false);
  const [forEdit, setForEdit] = useState(false);
  // delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // bind history to views to allow deep-linking
  const { location, push } = useHistory();
  useEffect(() => {
    if (!loading) {
      if (location.hash && location.hash === "#/new") {
        setForEdit(false);
        setShowEditPage(true);
        setShowDeleteModal(false);
      } else if (location.hash && location.hash.startsWith("#/delete/")) {
        const idForEdit = parseInt(location.hash.substring(9));
        if (!isNaN(idForEdit)) {
          const row = rows.find((r) => r.id == idForEdit);
          if (row !== undefined) {
            setForEdit(row);
            setShowEditPage(false);
            setShowDeleteModal(true);
          }
        }
      } else if (location.hash && location.hash.startsWith("#/edit/")) {
        const idForEdit = parseInt(location.hash.substring(7));
        if (!isNaN(idForEdit)) {
          const row = rows.find((r) => r.id == idForEdit);
          if (row !== undefined) {
            setForEdit(row);
            setShowEditPage(true);
            setShowDeleteModal(false);
          }
        }
      } else {
        setForEdit(false);
        setShowEditPage(false);
        setShowDeleteModal(false);
      }
    }
    /*eslint-disable-next-line*/
  }, [location.hash, loading]);

  // paging and sorting
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState(initialSorting);

  if (!showEditPage) {
    return (
      <>
        <DeleteModal
          show={showDeleteModal}
          name={forEdit.fullname}
          hideCallback={() => {
            // setShowDeleteModal(false);
            push('#/');
          }}
          deleteCallback={() => {
            apiClienti
              .remove(user.authToken, forEdit.id)
              .then(() => {
                // setShowDeleteModal(false);
                push('#/');
                triggerServerUpdate();
              });
          }}
        />
        <Card className="card-custom gutter-b">
          <Card.Header className="border-0 py-5 row">
            <Card.Title className="float-left col-auto">
              <h3 className="font-weight-bolder text-dark">Clienti</h3>
            </Card.Title>
            <TabellaToolbar
              addCallback={() => {
                // setForEdit(false);
                // setShowEditPage(true);
                push('#/new');
              }}
              className="float-right col-auto"
            />
          </Card.Header>
          <Card.Body className="pt-0">
            {!loading ? (
              <Grid rows={rows} columns={columns}>
                <ActionsColumn
                  editCallback={(id) => {
                    // setForEdit(rows.find((r) => r.id === id));
                    // setShowEditPage(true);
                    push(`#/edit/${id}`);
                  }}
                  deleteCallback={(id) => {
                    // setForEdit(rows.find((r) => r.id === id));
                    // setShowDeleteModal(true);
                    push(`#/delete/${id}`);
                  }}
                />
                <DateFormattedColumn for={["dateCreated"]} />
                <FilteringState />
                <IntegratedFiltering />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting />
                <Table
                  messages={tableMessages}
                  tableComponent={TableComponent}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow
                  messages={filterRowMessages}
                  cellComponent={FilterCell}
                />
                <PagingPanel
                  messages={pagingPanelMessages}
                  pageSizes={pageSizes}
                />
              </Grid>
            ) : (
              <div>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={518} />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  } else
    return (
      <EditNewCliente
        token={user.authToken}
        cliente={forEdit}
        hideEditPage={() => {
          // setShowEditPage(false);
          push('#/');
        }}
        triggerServerUpdate={triggerServerUpdate}
      />
    );
};
