import {post} from "./base";

export function getAll(accessToken) {
    return post("file/GetFiles", {jsonBody: {accessToken}});
}

export async function add(data) {
    // data: FormData -> remember to add accessToken
    await post("file/AddFile", {accessToken: data['accessToken'], formBody: data});
    console.log(`File "${data['name']}" added`);
  }

  export async function edit(accessToken, idFile, name, idAgente, type) {
    await post("file/EditFile", {jsonBody: {accessToken, idFile, name, idAgente, type}});
    console.log(`File ${idFile} edited`);
  }

export async function remove(accessToken, idFile) {
    await post("file/DeleteFile", {jsonBody: {accessToken, idFile}});
    console.log(`File ${idFile} deleted`);
}