import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as yup from "yup";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useSelector, shallowEqual } from "react-redux";
import * as apiUtenti from "../../../../api/users";
import * as apiClienti from "../../../../api/clienti";
import { icons, isAgente, roles } from "../../../../config/config";

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const capRegExp = /^[0-9]{5}$/;
const validationSchema = yup.object({
  fullname: yup
    .string("Inserire la regione sociale o il nome cliente")
    .required("Inserire la regione sociale o il nome cliente")
    .min(3, "Minimo tre caratteri")
    .max(150, "Massimo 150 caratteri"),
  email: yup
    .string("Inserire un'email valida")
    .email("Inserire un'email valida")
    .required("Inserire un'email valida")
    .min(3, "Minimo 3 caratteri")
    .max(150, "Massimo 150 caratteri"),
  phone: yup
    .string("Inserire un numero di telefono valido")
    .required("Inserire un numero di telefono valido")
    .min(9, "Minimo 9 cifre")
    .max(15, "Massimo 15 cifre")
    .matches(phoneRegExp, "Solo numeri"),
  provincia: yup
    .string("Inserire una provincia valida. ES: NA")
    .notRequired()
    .min(2, "La provincia deve contenere massimo 2 caratteri. ES: NA")
    .max(2, "La provincia deve contenere massimo 2 caratteri. ES: NA"),
  comune: yup
    .string("Inserire un comune valido")
    .notRequired()
    .min(3, "Minimo tre caratteri")
    .max(150, "Massimo 150 caratteri"),
  address: yup
    .string("Inserire un indirizzo valido")
    .notRequired()
    .min(3, "Minimo tre caratteri")
    .max(150, "Massimo 150 caratteri"),
  cap: yup
    .string("Inserire un CAP valido")
    .min(5, "Il CAP deve essere composto da 5 cifre")
    .max(5, "Il CAP deve essere composto da 5 cifre")
    .matches(capRegExp, "Il CAP deve essere composto da soli numeri"),
  idAgente: yup
    .number("Inserire un agente valido")
    .positive("Selezionare un agente")
    .required("Seleziona un agente"),
  partitaiva: yup
    .string("Inserire una partita IVA valida")
    .required("Inserire una partita IVA valida")
    .min(11, "Minimo 11 caratteri")
    .max(11, "Massimo 11 caratteri"),
  codicepec: yup
    .string("Inserire un codice valido")
    .required("Inserire un codice valido")
    .min(3, "Minimo 3 caratteri")
    .max(150, "Massimo 150 caratteri"),
  referente: yup
    .string("Inserire un referente valido")
    .notRequired()
    .min(3, "Minimo 3 caratteri")
    .max(150, "Massimo 150 caratteri"),
});

const actionsMessages = {
  new: "Crea",
  edit: "Modifica",
  reset: "Reset",
};

const formFields = {
  fullname: {
    label: "Rag. sociale / Nome completo",
    placeholder: "Rag. sociale / Nome completo...",
  },
  email: {
    label: "Email",
    placeholder: "Email cliente...",
  },
  phone: {
    label: "Telefono",
    placeholder: "Telefono cliente...",
  },
  provincia: {
    label: "Provincia",
    placeholder: "Provincia...",
  },
  comune: {
    label: "Comune",
    placeholder: "Comune...",
  },
  address: {
    label: "Indirizzo",
    placeholder: "Indirizzo cliente...",
  },
  cap: {
    label: "CAP",
    placeholder: "Indirizzo cliente...",
  },
  idAgente: {
    label: "Selezionare l'agente",
    placeholder: "Agente...",
  },
  partitaiva: {
    label: "Partita IVA",
    placeholder: "Partita IVA...",
  },
  codicepec: {
    label: "Codice SDI / PEC",
    placeholder: "Codice...",
  },
  referente: {
    label: "Referente consegna",
    placeholder: "Nome e cognome referente...",
  },
};

const Toolbar = ({
  submitCallback,
  closeCallback,
  resetCallback,
  loading,
  alreadyExisting,
  ...props
}) => {
  return (
    <div {...props}>
      <Button
        disabled={loading}
        onClick={submitCallback}
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-light-primary">
          <SVG src={toAbsoluteUrl(icons.ok)} />
        </span>
        {alreadyExisting ? actionsMessages.edit : actionsMessages.new}
      </Button>
      <Button
        disabled={loading}
        onClick={resetCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.reset)} />
        </span>
        {actionsMessages.reset}
      </Button>
      <Button
        onClick={closeCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.go_back)} />
        </span>
        Clienti
      </Button>
    </div>
  );
};

export const EditNewCliente = (props) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [agenti, setAgenti] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialState = {
    id: false,
    fullname: "",
    provincia: "",
    comune: "",
    cap: "",
    idAgente: parseInt(user.idRole) === roles.admin ? -1 : user.id,
    email: "",
    phone: "",
    address: "",
  };
  const alreadyExisting = props.cliente !== false;
  if (alreadyExisting) {
    Object.assign(initialState, props.cliente);
  }

  useEffect(() => {
    if (parseInt(user.idRole) === roles.admin) {
      apiUtenti
        .getAll(props.token)
        .then((data) =>
          setAgenti(
            data
              .filter((user) => isAgente(parseInt(user.role)))
              .map((agente) => ({ value: agente.id, label: agente.fullname }))
          )
        );
    }
    /*eslint-disable-next-line*/
  }, []);

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const result = {
        ...values,
        idCliente: alreadyExisting ? initialState.id : "",
      };
      apiClienti
        .add(props.token, result)
        .then(() => setLoading(false))
        .then(() => props.triggerServerUpdate())
        .then(() => props.hideEditPage());
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  return (
    <Card className="card-custom gutter-b">
      <Card.Header className="border-0 py-5 row">
        <Card.Title className="float-left col-auto">
          <h3 className="font-weight-bolder text-dark">
            {alreadyExisting
              ? actionsMessages.edit + " cliente"
              : actionsMessages.new + " cliente"}
          </h3>
        </Card.Title>
        <Toolbar
          submitCallback={formik.handleSubmit}
          resetCallback={formik.handleReset}
          closeCallback={props.hideEditPage}
          loading={loading}
          alreadyExisting={alreadyExisting}
          className="float-right col-auto"
        />
      </Card.Header>
      <Card.Body className="pt-0">
        <Form className="row">
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["fullname"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="fullname"
              placeholder={formFields["fullname"].placeholder}
              className={getInputClasses("fullname")}
              {...formik.getFieldProps("fullname")}
            />
            {formik.touched["fullname"] && formik.errors["fullname"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["fullname"]}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["email"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder={formFields["email"].placeholder}
              className={getInputClasses("email")}
              {...formik.getFieldProps("email")}
            />
            {formik.touched["email"] && formik.errors["email"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["email"]}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["phone"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="phone"
              placeholder={formFields["phone"].placeholder}
              className={getInputClasses("phone")}
              {...formik.getFieldProps("phone")}
            />
            {formik.touched["phone"] && formik.errors["phone"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["phone"]}</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["provincia"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="provincia"
              placeholder={formFields["provincia"].placeholder}
              className={getInputClasses("provincia")}
              comune
              {...formik.getFieldProps("provincia")}
            />
            {formik.touched["provincia"] && formik.errors["provincia"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors["provincia"]}
                </div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["comune"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="comune"
              placeholder={formFields["comune"].placeholder}
              className={getInputClasses("comune")}
              {...formik.getFieldProps("comune")}
            />
            {formik.touched["comune"] && formik.errors["comune"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["comune"]}</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["address"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder={formFields["address"].placeholder}
              className={getInputClasses("address")}
              {...formik.getFieldProps("address")}
            />
            {formik.touched["address"] && formik.errors["address"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["address"]}</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["cap"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="cap"
              placeholder={formFields["cap"].placeholder}
              className={getInputClasses("cap")}
              {...formik.getFieldProps("cap")}
            />
            {formik.touched["cap"] && formik.errors["cap"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors["cap"]}</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["partitaiva"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="partitaiva"
              placeholder={formFields["partitaiva"].placeholder}
              className={getInputClasses("partitaiva")}
              {...formik.getFieldProps("partitaiva")}
            />
            {formik.touched["partitaiva"] && formik.errors["partitaiva"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors["partitaiva"]}
                </div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["codicepec"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="codicepec"
              placeholder={formFields["codicepec"].placeholder}
              className={getInputClasses("codicepec")}
              {...formik.getFieldProps("codicepec")}
            />
            {formik.touched["codicepec"] && formik.errors["codicepec"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors["codicepec"]}
                </div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields["referente"].label}
            </Form.Label>
            <Form.Control
              type="text"
              name="referente"
              placeholder={formFields["referente"].placeholder}
              className={getInputClasses("referente")}
              {...formik.getFieldProps("referente")}
            />
            {formik.touched["referente"] && formik.errors["referente"] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors["referente"]}
                </div>
              </div>
            ) : null}
          </Form.Group>

          {parseInt(user.idRole) === roles.admin ? (
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">
                {formFields["idAgente"].label}
              </Form.Label>
              <select
                type="text"
                name="idAgente"
                className={`form-control ${getInputClasses("idAgente")}`}
                {...formik.getFieldProps("idAgente")}
              >
                <option value={0}>{formFields["idAgente"].placeholder}</option>
                {agenti.map((e, key) => (
                  <option key={key} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </select>
              {formik.touched["idAgente"] && formik.errors["idAgente"] ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors["idAgente"]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          ) : null}
        </Form>
      </Card.Body>
    </Card>
  );
};
