/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";
import { menuIcons, roles, isAgente } from "../../../../../app/config/config";
import Divider from "@material-ui/core/Divider";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);
  const userIsAgente = isAgente(parseInt(user.idRole));
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false, urls = []) => {
    return checkIsActive(location, url) || urls.some((url) => checkIsActive(location, url))
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  
  const NiceDivider = () => (
    <li key="nice-divider" style={{ padding: '16px' }}>
      <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.12)' }} />
    </li>
  );
  
  const renderMenuItem = ({
    url,
    title,
    iconUrl,
    hasSubmenu = false,
    openInNewWindow = false,
  }) => {
    const key = url; // Chiave stabile e unica
    const icon = iconUrl ? (
      <span className="svg-icon menu-icon">
        <SVG src={toAbsoluteUrl(iconUrl)} />
      </span>
    ) : (
      <i className="menu-bullet menu-bullet-dot">
        <span />
      </i>
    );
  
    return (
      <li
        className={`menu-item ${getMenuItemActive(url, hasSubmenu)}`}
        aria-haspopup="true"
        key={key}
      >
        {!openInNewWindow ? (
          <NavLink className="menu-link" to={url}>
            {icon}
            <span className="menu-text">{title}</span>
          </NavLink>
        ) : (
          <a className="menu-link" href={url} target="_blank">
            {icon}
            <span className="menu-text">{title}</span>
          </a>
        )}
      </li>
    );
  };
  
  const renderMenu = ({ url, title, iconUrl, items }) => {
    return (
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          url,
          true,
          items.map((item) => item["url"])
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
        key={url} // Chiave unica
      >
        <NavLink className="menu-link menu-toggle" to={url}>
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl(iconUrl)} />
          </span>
          <span className="menu-text">{title}</span>
          <i className="menu-arrow" />
        </NavLink>
        <div className="menu-submenu">
          <ul className="menu-subnav">
            <li
              className="menu-item  menu-item-parent"
              aria-haspopup="true"
              key={"parent_" + title} // Chiave più stabile
            >
              <span className="menu-link">
                <span className="menu-text">{title}</span>
              </span>
            </li>
            {items.map((item, index) => (
              renderMenuItem({
                url: item.url,
                title: item.title,
                iconUrl: item.iconUrl,
                hasSubmenu: false,
                key: item.url + "_" + index, // Chiave stabile e unica
              })
            ))}
          </ul>
        </div>
      </li>
    );
  };


  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* {renderMenuItem({
          url: "/dashboard",
          title: "Dashboard",
          iconUrl: menuIcons.dashboard,
        })} */}
        {role === roles.admin || role === roles.developer || role === roles.magazziniere
          ? renderMenu({
              url: "/admin",
              title: "Admin",
              iconUrl: menuIcons.admin,
              items: [
                ...(role === roles.admin || role === roles.developer ? [
                  {
                    url: "/admin/utenti/",
                    title: "Utenti",
                  },
                  {
                    url: "/medici/",
                    title: "Medici",
                  },
                ] : []), 
                {
                  url: "/clienti/",
                  title: "Clienti",
                },
                {
                  url: "/gestione-campioni/",
                  title: "Campioni",
                },
                {
                  url: "/gestione-materiale/",
                  title: "Materiale Pubblicitario",
                },
                ...(role === roles.admin || role === roles.developer ? [
                  {
                    url: "/gestione-prodotti/",
                    title: "Prodotti",
                  },
                ] : []), 
                /* {
              url: "/admin/costs",
              title: "Costi"
            }, */
              ],
            })
          : null}
        
        {/* Sezione Gestione */}
        <NiceDivider />
        
        {renderMenuItem({
          url: "/file/",
          title: "File",
          iconUrl: menuIcons.files,
        })}
        {role === roles.developer
        ? renderMenu({
          url: "/eventi",
          title: "Eventi",
          iconUrl: menuIcons.events,
          items: [
            {
              url: "/calendario",
              title: "Calendario"
            },
            {
              url: "/agenda",
              title: "Agenda"
            },
            {
              url: "/richiesta-ferie-permessi",
              title: "Richieste"
            },
          ]
        })
      : null}
        {/* {renderMenuItem({
          url: "/calendario/",
          title: "Calendario",
          iconUrl: menuIcons.calendar,
        })} */}
        {renderMenuItem({
          url: "https://webmail.aruba.it/",
          title: "Aruba mail",
          iconUrl: menuIcons.mail,
          openInNewWindow: true,
        })}
        {/* renderMenu({
          url: "/eventi",
          title: "Eventi",
          iconUrl: menuIcons.events,
          items: [
            {
              url: "/eventi/calendario",
              title: "Calendario"
            },
            {
              url: "/eventi/agenda",
              title: "Agenda"
            },
            {
              url: "/eventi/richieste",
              title: "Richieste"
            },
          ]
        }) */}

        {/* Sezione Campionature e servizi */}
        {role === roles.admin || role === roles.developer || role === roles.magazziniere || userIsAgente 
          ? <>
            <NiceDivider />

            {renderMenuItem({
              url: "/richiesta-campioni/",
              title: "Campioni",
              iconUrl: menuIcons.campioni,
            })}
            {renderMenuItem({
              url: "/richiesta-materiale/",
              title: "Materiale Pubblicitario",
              iconUrl: menuIcons.marketing_material,
            })}
            {role !== roles.magazziniere
              ? renderMenuItem({
                  url: "/servizi/",
                  title: "Servizi",
                  iconUrl: menuIcons.services,
                })
              : null}
            {userIsAgente
              ? renderMenuItem({
                  url: "/medici/",
                  title: "Medici",
                  iconUrl: menuIcons.medics,
                })
              : null}
            {renderMenuItem({
              url: "/status-campionatura-servizi/",
              title: "Riepilogo",
              iconUrl: menuIcons.status,
            })}
          </>
          : null}

        {/* Sezione Vendita */}
        {role === roles.admin || role == roles.developer || role === roles.magazziniere || role === roles.contabile || (userIsAgente && parseInt(user.acquisti)) 
          ? <>
            <NiceDivider />
            {renderMenuItem({
              url: "/richiesta-prodotti/",
              title: "Vendite Dirette",
              iconUrl: menuIcons.products
            })}
            {userIsAgente
              ? renderMenuItem({
                  url: "/clienti/",
                  title: "Clienti",
                  iconUrl: menuIcons.clients
                })
              : null}
            {renderMenuItem({
              url: "/status-vendita/",
              title: "Riepilogo",
              iconUrl: menuIcons.status
            })}
          </>
          : null
        }
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
