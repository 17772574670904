import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { HeaderMenu } from "./HeaderMenu";
import { useLocation } from "react-router-dom";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../../_core/MetronicSubheader";

export function HeaderMenuWrapper() {
  
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      ktMenuClasses: uiService.getClasses("header_menu", true),
      rootArrowEnabled: objectPath.get(
        uiService.config,
        "header.menu.self.root-arrow"
      ),
      menuDesktopToggle: objectPath.get(
        uiService.config,
        "header.menu.desktop.toggle"
      ),
      headerMenuAttributes: uiService.getAttributes("header_menu"),
      headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
      ulClasses: uiService.getClasses("header_menu_nav", true),
      disabledAsideSelfDisplay:
        objectPath.get(uiService.config, "aside.self.display") === false,
    };
  }, [uiService]);

  const getHeaderLogo = () => {
    let result = "logo-light.png";
    if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
      result = "logo-dark.png";
    }
    return toAbsoluteUrl(`/media/logos/${result}`);
  };

  return (
    <>
      {/*begin::Header Title*/}
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          padding: 0,
          alignSelf: "center",
        }}
      >
        <h5 className="text-dark font-weight-bold my-2 mr-5">
          <>{subheader.title}</>
          {/*<small></small>*/}
        </h5>
      </div>
      {/*end::Header Title*/}
      {false && (
        <>
          {/* begin::Header Menu Wrapper*/}
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          >
            {layoutProps.disabledAsideSelfDisplay && (
              <>
                {/*begin::Header Logo*/}
                <div className="header-logo">
                  <Link to="/">
                    <img alt="logo" src={getHeaderLogo()} />
                  </Link>
                </div>
                {/*end::Header Logo*/}
              </>
            )}

            {/*begin::Header Menu*/}
            <HeaderMenu layoutProps={layoutProps} />
            {/*end::Header Menu*/}
          </div>
          {/*Header Menu Wrapper */}
        </>
      )}
    </>
  );
}
