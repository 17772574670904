import moment from "moment-timezone";
import React, {useState} from "react";
import { Modal, Button } from "react-bootstrap";

export const ModalVisualizzaEvento = ({ selectedEvent, show, setShow }) => {

  const event = useState(selectedEvent.extendProps ? selectedEvent.extendProps : {
    title: "",
    descrizione: "",
    department: 0,
    startDate: moment(),
    endDate: moment(),
  });

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-center">
        <Modal.Title className="text-center">{event.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>info evento</Modal.Body>
      <Modal.Footer className="border-0">
        <Button onClick={() => setShow(false)}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
};
