/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
//import { Form } from "react-bootstrap";
import { Chip, Avatar } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
//import { Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger, Button, Modal } from "react-bootstrap";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { icons, roles, colors } from "../../../../config/config";
import * as apiUtenti from "../../../../api/users";
import { EditNewUtente } from "./EditNewUtente";

const TableComponent = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className="table table-hover table-head-custom table-vertical-center overflow-hidden"
  />
);

const TabellaCampioniToolbar = ({ addCallback, ...props }) => {
  return (
    <div {...props}>
      <Button onClick={addCallback} className="float-right">
        Aggiungi dipendente
      </Button>
    </div>
  );
};

const FilterCell = (props) => {
  const { column } = props;
  if (column.name === "actions") {
    const ActionsFilterCell = ({ filter, onFilter }) => (
      <th style={{ fontWeight: "normal" }}>
        <input disabled style={{ visibility: "hidden" }} />
      </th>
    );
    ActionsFilterCell.propTypes = {
      filter: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
      onFilter: PropTypes.func.isRequired,
    };
    ActionsFilterCell.defaultProps = {
      filter: null,
    };
    return <ActionsFilterCell {...props} />;
  }
  // if (column.name === "idRole") {
  //   const RoleFilterCell = ({ filter, onFilter }) => (
  //     <th style={{ fontWeight: "normal" }}>
  //       <Form.Select
  //         aria-label="Seleziona un ruolo"
  //         value={filter}
  //         onChange={(event) => onFilter(event.target == -1 ? null : event.target)}
  //       >
  //         <option>Seleziona un ruolo</option>
  //         <option value="0">Admin</option>
  //         <option value="2">Agente Roydermal</option>
  //         <option value="1">Agente Roypet</option>
  //         <option value="3">Agente completo</option>
  //         <option value="4">Magazziniere</option>
  //       </Form.Select>
  //     </th>
  //   );
  //   RoleFilterCell.propTypes = {
  //     filter: PropTypes.shape({
  //       value: PropTypes.oneOfType([PropTypes.number])
  //         .isRequired,
  //     }),
  //     onFilter: PropTypes.func.isRequired,
  //   };
  //   RoleFilterCell.defaultProps = {
  //     filter: null,
  //   };
  //   return <RoleFilterCell {...props} />;
  // }
  return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};
/*eslint-disable-next-line*/
const DateFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => moment(value).format("DD MMMM YYYY")}
    {...props}
  />
);

const AziendaColumn = ({ value }) => {
  value = parseInt(value);
  var admin = value === roles.admin;
  var dev = value === roles.developer;
  var rp = value === roles.agenteRoypet;
  var rd = value === roles.agenteRoydermal;
  var all = value === roles.agenteCompleto;
  var mag = value === roles.magazziniere;
  var dip = value === roles.dipendente;
  var gra = value === roles.grafico;
  var cont = value === roles.contabile;
  return (
    <div className="pl-3">
      {admin ? (
        <Chip
          style={{ color: colors.admin, borderColor: colors.admin }}
          variant="outlined"
          avatar={
            <Avatar
              style={{ color: "#fff", backgroundColor: colors.admin }}
              alt="A"
              // src={toAbsoluteUrl("media/products/roypet.png")}
              src="_blank"
            />
          }
          label="Admin"
        />
      ) : null}
      {dev ? (
        <Chip
          style={{ color: colors.developer, borderColor: colors.developer }}
          variant="outlined"
          avatar={
            <Avatar
              style={{ color: "#fff", backgroundColor: colors.developer }}
              alt="D"
              // src={toAbsoluteUrl("media/products/roypet.png")}
              src="_blank"
            />
          }
          label="Developer"
        />
      ) : null}
      {cont ? (
        <Chip
          style={{ color: colors.contabile, borderColor: colors.contabile }}
          variant="outlined"
          avatar={
            <Avatar
              style={{ color: "#fff", backgroundColor: colors.contabile }}
              alt="€"
              // src={toAbsoluteUrl("media/products/roypet.png")}
              src="_blank"
            />
          }
          label="Contabile"
        />
      ) : null}
      {gra ? (
        <Chip
          style={{ color: colors.grafico, borderColor: colors.grafico }}
          variant="outlined"
          avatar={
            <Avatar
              style={{ color: "#fff", backgroundColor: colors.grafico }}
              alt="G"
              // src={toAbsoluteUrl("media/products/roypet.png")}
              src="_blank"
            />
          }
          label="Grafico"
        />
      ) : null}
      {rp ? (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-edit-tooltip">
              Agente abilitato a prodotti Roypet
            </Tooltip>
          }
        >
          <Chip
            style={{
              color: colors.agenteRoypet,
              borderColor: colors.agenteRoypet,
            }}
            variant="outlined"
            avatar={
              <Avatar
                style={{ color: "#fff", backgroundColor: colors.agenteRoypet }}
                alt="RP"
                // src={toAbsoluteUrl("media/products/roypet.png")}
                src="_blank"
              />
            }
            label="Roypet"
          />
        </OverlayTrigger>
      ) : null}
      {rd ? (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-edit-tooltip">
              Agente abilitato a prodotti Roydermal
            </Tooltip>
          }
        >
          <Chip
            style={{
              color: colors.agenteRoydermal,
              borderColor: colors.agenteRoydermal,
            }}
            variant="outlined"
            avatar={
              <Avatar
                style={{
                  color: "#fff",
                  backgroundColor: colors.agenteRoydermal,
                }}
                alt="RD"
                // src={toAbsoluteUrl("media/products/roydermal.png")}
                src="_blank"
              />
            }
            label="Roydermal"
          />
        </OverlayTrigger>
      ) : null}
      {all ? (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-edit-tooltip">
              Agente abilitato a tutti i prodotti
            </Tooltip>
          }
        >
          <Chip
            style={{
              color: colors.agenteCompleto,
              borderColor: colors.agenteCompleto,
            }}
            variant="outlined"
            avatar={
              <Avatar
                style={{
                  color: "#fff",
                  backgroundColor: colors.agenteCompleto,
                }}
                alt="ALL"
                // src={toAbsoluteUrl("media/products/roydermal.png")}
                src="_blank"
              />
            }
            label="Completo"
          />
        </OverlayTrigger>
      ) : null}
      {mag ? (
        <Chip
          style={{
            color: colors.magazziniere,
            borderColor: colors.magazziniere,
          }}
          variant="outlined"
          avatar={
            <Avatar
              style={{ color: "#fff", backgroundColor: colors.magazziniere }}
              alt="M"
              // src={toAbsoluteUrl("media/products/roydermal.png")}
              src="_blank"
            />
          }
          label="Magazziniere"
        />
      ) : null}
      {dip ? (
        <Chip
          style={{
            color: colors.dipendente,
            borderColor: colors.dipendente,
          }}
          variant="outlined"
          avatar={
            <Avatar
              style={{ color: "#fff", backgroundColor: colors.dipendente }}
              alt="D"
              // src={toAbsoluteUrl("media/products/roydermal.png")}
              src="_blank"
            />
          }
          label="Dipendente"
        />
      ) : null}
    </div>
  );
};

const AziendaFormattedColumn = (props) => (
  <DataTypeProvider formatterComponent={AziendaColumn} {...props} />
);


const useStyles = makeStyles({
  abilitato: {
      width: 25,
      height: 25,
      backgroundColor: 'var(--danger)',
  },
});

const AcquistiColumn = ({ value }) => {
  const classes = useStyles();
  /*eslint-disable-next-line*/
  if (value == true)
      return (
          <Avatar className={classes.abilitato}>
              <span className="svg-icon svg-icon-md svg-icon-white">
                  {" "}
                  <SVG src={toAbsoluteUrl(icons.ok)} />
              </span>
              {/* <i className="ki ki-plus icon-white"></i> */}
          </Avatar>
      );
  return (<></>);
};

export const AcquistiFormattedColumn = (props) => (
  <DataTypeProvider formatterComponent={AcquistiColumn} {...props} />
);

const ActionsColumn = ({ editCallback, deleteCallback }) => (
  <DataTypeProvider
    for={["actions"]}
    formatterComponent={({ value }) => (
      <>
        <OverlayTrigger
          overlay={<Tooltip id="products-edit-tooltip">Modifica</Tooltip>}
        >
          {/*eslint-disable-next-line*/}
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => editCallback(value)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(icons.edit)} />
            </span>
          </a>
        </OverlayTrigger>

        <> </>
        <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">Elimina</Tooltip>}
        >
          {/*eslint-disable-next-line*/}
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => deleteCallback(value)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl(icons.delete)} />
            </span>
          </a>
        </OverlayTrigger>
      </>
    )}
  />
);

const DeleteModal = ({ name, show, hideCallback, deleteCallback }) => (
  <Modal show={show} onHide={hideCallback} className="card-custom gutter-b">
    <Modal.Header className="border-0 py-5 row">
      <Modal.Title>
        <h3 className="font-weight-bolder text-dark">Elimina utente</h3>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-4">
      Sei sicuro di voler eliminare definitivamente "{name}"
    </Modal.Body>
    <Modal.Footer className="border-0 py-5 row">
      <Button onClick={deleteCallback} variant="danger" className="float-right">
        Elimina
      </Button>
      <Button
        onClick={hideCallback}
        variant="secondary"
        className="float-right"
      >
        Annulla
      </Button>
    </Modal.Footer>
  </Modal>
);

// Columns definition
const columns = [
  { name: "fullname", title: "Nome" },
  { name: "role", title: "Ruolo" },
  { name: "acquisti", title: "Vendita" },
  { name: "email", title: "Email" },
  { name: "region", title: "Regione assegnata" },
  { name: "address", title: "Indirizzo" },
  { name: "phone", title: "Telefono" },
  { name: "actions", title: "Azioni" },
];

// Messages
const tableMessages = {
  noData: "Nessun dipendente disponibile",
};
const filterRowMessages = {
  filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
  showAll: "Tutte",
  rowsPerPage: "Righe per pagina",
  info: "Da {from} a {to} ({count} dipendenti)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "fullname", direction: "desc" }];

export const Utenti = () => {
  // table data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [_internalUpdateCount, _setInternalUpdateCount] = useState(0);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  //const role = parseInt(user.idRole);

  const updateFromServer = () => {
    setLoading(true);
    apiUtenti
      .getAll(user.authToken)
      // row.actions is passed to the btn constructors which passes it to the callbacks
      .then((data) => setRows(data.map((row) => ({ ...row, actions: row.id }))))
      .then(() => setLoading(false));
  };
  useEffect(() => {
    updateFromServer();
    /*eslint-disable-next-line*/
  }, [_internalUpdateCount]);

  // edit page
  const [showEditPage, setShowEditPage] = useState(false);
  const [utenteForEdit, setUtenteForEdit] = useState(false);
  // delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // bind history to views to allow deep-linking
  const { location, push } = useHistory();
  useEffect(() => {
    if (!loading) {
      if (location.hash && location.hash === "#/new") {
        setUtenteForEdit(false);
        setShowEditPage(true);
        setShowDeleteModal(false);
      } else if (location.hash && location.hash.startsWith("#/delete/")) {
        const idForEdit = parseInt(location.hash.substring(9));
        if (!isNaN(idForEdit)) {
          const row = rows.find((r) => r.id == idForEdit);
          if (row !== undefined) {
            setUtenteForEdit(row);
            setShowEditPage(false);
            setShowDeleteModal(true);
          }
        }
      } else if (location.hash && location.hash.startsWith("#/edit/")) {
        const idForEdit = parseInt(location.hash.substring(7));
        if (!isNaN(idForEdit)) {
          const row = rows.find((r) => r.id == idForEdit);
          if (row !== undefined) {
            setUtenteForEdit(row);
            setShowEditPage(true);
            setShowDeleteModal(false);
          }
        }
      } else {
        setUtenteForEdit(false);
        setShowEditPage(false);
        setShowDeleteModal(false);
      }
    }
    /*eslint-disable-next-line*/
  }, [location.hash, loading]);

  // paging and sorting
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState(initialSorting);

  if (!showEditPage) {
    return (
      <>
        <DeleteModal
          show={showDeleteModal}
          name={utenteForEdit.fullname}
          hideCallback={() => {
            // setShowDeleteModal(false);
            push('#/');
          }}
          deleteCallback={() => {
            apiUtenti.remove(user.authToken, utenteForEdit.id).then(() => {
              // setShowDeleteModal(false);
              push('#/');
              _setInternalUpdateCount(_internalUpdateCount + 1);
            });
          }}
        />
        <Card className="card-custom gutter-b">
          <Card.Header className="border-0 py-5 row">
            <Card.Title className="float-left col-auto">
              <h3 className="font-weight-bolder text-dark">Dipendenti</h3>
            </Card.Title>
            <TabellaCampioniToolbar
              addCallback={() => {
                // setUtenteForEdit(false);
                // setShowEditPage(true);
                push('#/new');
              }}
              className="float-right col-auto"
            />
          </Card.Header>
          <Card.Body className="pt-0">
            {!loading ? (
              <Grid rows={rows} columns={columns}>
                <ActionsColumn
                  editCallback={(id) => {
                    // setUtenteForEdit(rows.find((r) => r.id === id));
                    // setShowEditPage(true);
                    push(`#/edit/${id}`);
                  }}
                  deleteCallback={(id) => {
                    // setUtenteForEdit(rows.find((r) => r.id === id));
                    // setShowDeleteModal(true);
                    push(`#/delete/${id}`);
                  }}
                />
                <AziendaFormattedColumn for={["role"]} />
                <AcquistiFormattedColumn for={["acquisti"]} />
                <FilteringState />
                <IntegratedFiltering />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting />
                <Table
                  messages={tableMessages}
                  tableComponent={TableComponent}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow
                  messages={filterRowMessages}
                  cellComponent={FilterCell}
                />
                <PagingPanel
                  messages={pagingPanelMessages}
                  pageSizes={pageSizes}
                />
              </Grid>
            ) : (
              <div>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={518} />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  } else
    return (
      <EditNewUtente
        token={user.authToken}
        utente={utenteForEdit}
        hideEditPage={() => {
          // setShowEditPage(false);
          push('#/');
        }}
        triggerServerUpdate={() =>
          _setInternalUpdateCount(_internalUpdateCount + 1)
        }
      />
    );
};
