import {post} from "./base";

export function getAll(accessToken) {
  return post("prodotti/GetProdotti", {accessToken});
}

export async function add(data) {
  // data: FormData -> remember to add accessToken
  await post("prodotti/AddProdotto", {accessToken: data['accessToken'], formBody: data});
  console.log(`Prodotto ${data['id']} added`);
}
export async function remove(accessToken, idProdotto) {
  await post("prodotti/DeleteProdotto", {jsonBody: {accessToken, idProdotto}});
  console.log(`Prodotto ${idProdotto} deleted`);
}

export async function setAvailability(accessToken, idProdotto, value) {
  await post("prodotti/UpdateAvailability", {jsonBody: {
    accessToken, 
    id: idProdotto, 
    available: value,
  }});
  console.log(`Prodotto ${idProdotto} set as ${value ? 'available' : 'unavailable'}`);
}
