import { post } from "./base";
import { statusRichiestaCalendario } from "../config/config";

export function getEventi(accessToken, from, to) {
  return post("calendario/GetEventi", {accessToken, jsonBody: {from, to}});
}

export async function addEvento(data) {
  await post("calendario/AddEvento", {
    accessToken: data.accessToken,
    jsonBody: data,
  });
  console.log(`Evento ${data['id']} added`);
}

export async function removeEvento(accessToken, idEvento) {
  await post("calendario/DeleteEvento", {jsonBody: {accessToken, idEvento}});
  console.log(`Evento ${idEvento} deleted`);
}

export function getRichieste(accessToken, from, to) {
  return post("calendario/GetPermessi", {accessToken, jsonBody: {from, to}});
}

export async function addRichiesta(data) {
  await post("calendario/AddPermesso", {
    accessToken: data.accessToken,
    jsonBody: data,
  });
  console.log(`Richiesta ${data['id']} added`);
}

export async function removeRichiesta(accessToken, idRichiesta) {
  await post("calendario/DeletePermesso", {jsonBody: {accessToken, idPermesso: idRichiesta}});
  console.log(`Richiesta ${idRichiesta} deleted`);
}

export async function updateRichiesta(accessToken, idRichiesta, status) {
  await post("calendario/UpdateStatusPermesso", {jsonBody: {
      accessToken,
      idPermesso: idRichiesta,
      status,
  }});
  console.log(`Richiesta ferie/permessi ${idRichiesta} updated with state ${Object.keys(statusRichiestaCalendario).find((k) => statusRichiestaCalendario[k] === status)}`);
}