import moment from "moment-timezone";
import "moment/locale/it";

const TimenozeInMinutes = () => {
  var tz_guess = moment.tz.guess();
  var tz_tm = moment.tz(tz_guess).format("Z");

  var sign = tz_tm.charAt(0);
  var result = tz_tm.substring(1) + ":00";
  var hms = result; // your input string
  var a = hms.split(":"); // split it at the colons
  // Hours are worth 60 minutes.
  var minutes = +a[0] * 60 + +a[1];

  return sign + "" + minutes;
};

moment.locale("it");

function GetBrowser() {
  var name;
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    name = "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    name = "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    name = "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    name = "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    name = "IE";
  } else {
    name = "unknown";
  }

  return name;
}

const config = {
  apiUrl: process.env.REACT_APP_API_URL + "/inc/api/",
  siteUrl: window.location.origin + "/dashboard",
  sitePath: window.location.origin,
  timezone: TimenozeInMinutes(),
  browser: GetBrowser(),
  keyApi: "922406474ca4705e3f3035831c44449cb811edc1cd751548fef02ad83b4ca6af",
};
export default config;

// Description for auction
export const SetDataTimeZone = (date, format) => {
  function RefreshDataTimeZone(date) {
    var dateType = date;

    var event = new Date(dateType);

    event.setMinutes(TimenozeInMinutes());

    var dateSet = moment(event).format(format);

    return dateSet;
  }

  return RefreshDataTimeZone(date);
};

// from a full date-time-offset ISO8601/RFC3339 value
// console.log(SetDataTimeZone('2017/05/22 02:00', "YYYY-MM-DD HH:mm:ss"))

export const Pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const roles = {
  admin: 1,
  agenteRoypet: 2,
  agenteRoydermal: 3,
  agenteCompleto: 4,
  magazziniere: 5,
  dipendente: 6,
  grafico: 7,
  developer: 8,
  contabile: 9,
};

export const isAgente = (role) =>
  role === roles.agenteRoydermal ||
  role === roles.agenteRoypet ||
  role === roles.agenteCompleto;

export const colors = {
  admin: "#d95e18",
  agenteRoypet: "#323e86",
  agenteRoydermal: "#ef233c",
  agenteCompleto: "#c72b7d",
  magazziniere: "#2a9d8f",
  dipendente: "#e29578",
  grafico: "#bfcf9c",
  developer: "#333333",
  contabile: "#B89C00",
};

export const statusRichiesta = {
  inAttesa: 0,
  inevaso: 1, // pronto per essere ritirato o spedito
  evaso: 2, // ordine ritirato o spedito
  rifiutato: 3, // cancellato da agente o rifiutato da magazziniere
  approvato: 4, // in caso sia per una vendita (approvata dall'admin)
};

export const statusRichiestaServizio = {
  inAttesa: 0,
  approvato: 1, //
  annullato: 2, // cancellato da agente o rifiutato da magazziniere
};

export const statusRichiestaCalendario = {
  inAttesa: 0,
  approvato: 1, //
  annullato: 2, // cancellato da utente o rifiutato da admin
};

export const categorieFile = {
  manuali: 1,
  datiVendita: 2,
  rendiconti: 0,
  documenti: 3,
};

export const categorieRichiesteCalendario  = {
  ferie: 0,
  permesso: 1,
};

export const fileVisibilityGroups = [
  {
    value: 0,
    label: "Tutti",
  },
  {
    value: -1,
    label: "Roydermal",
  },
  {
    value: -2,
    label: "Roypet",
  },
];

export const nomiCategorieFile = {
  0: "rendiconto",
  1: "manuale",
  2: "dati vendite",
  3: "documento",
};
export const nomiCategorieFilePlural = {
  0: "Rendiconti",
  1: "Manuali",
  2: "Dati vendite",
  3: "Documenti",
};

export const metodiPagamento = {
  1: "Contrassegno alla consegna",
  2: "Bonifico anticipato su ordine",
  3: "Bonifico anticipato su ordine +5%",
  4: "Altro (Specificare nelle Note)",
}
export const scontiMetodiPagamento = {
  1: 0,
  2: 0, 
  3: 0.05,
  4: 0,
}






export const icons = {
  edit: "/media/svg/icons/Communication/Write.svg",
  delete: "/media/svg/icons/General/Trash.svg",
  addToCart: "/media/svg/icons/Shopping/Cart1.svg",
  close: "/media/svg/icons/Navigation/Close.svg",
  reset: "/media/svg/icons/Media/Repeat.svg",
  go_back: "/media/svg/icons/Navigation/Angle-left.svg",
  ok: "/media/svg/icons/Navigation/Check.svg",
  go: "/media/svg/icons/Communication/Send.svg",
  upload: "/media/svg/icons/Files/Upload.svg",
  //open: "/media/svg/icons/General/Visible.svg",
  download: "/media/svg/icons/Files/Download.svg",
  //preview: "/media/svg/icons/Files/Media.svg",
};
export const menuIcons = {
  medics: "/media/svg/icons/Communication/Group.svg",
  calendar: "/media/svg/icons/Layout/Layout-grid.svg",
  dashboard: "/media/svg/icons/Layout/Layout-4-blocks.svg",
  events: "/media/svg/icons/Layout/Layout-top-panel-6.svg", //"/media/svg/icons/Home/Clock.svg",
  files: "/media/svg/icons/Home/Commode1.svg",
  admin: "/media/svg/icons/General/Settings-1.svg", //"/media/svg/icons/Communication/Group.svg",
  mail: "/media/svg/icons/Communication/Mail.svg",
  campioni: "/media/svg/icons/Shopping/Box2.svg",
  marketing_material: "/media/svg/icons/Home/Book-open.svg",
  status: "/media/svg/icons/Shopping/Loader.svg",
  services: "/media/svg/icons/Home/Earth.svg",
  products: "/media/svg/icons/Shopping/Box3.svg",
  clients: "/media/svg/icons/Communication/Group.svg",
};

