import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ShopGrid, ShopGridSkeleton } from "../../modules/Shop/ShopGrid";
import * as apiProdotti from "../../api/prodotti"
import * as apiRichiesteVendita from "../../api/richiesteVendita"
import { roles, isAgente } from "../../config/config";

export const ShopProdotti = ({ elements }) => {
  const [loading, setLoading] = useState(false);
  const [prodotti, setProdotti] = useState([]);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);
  const abilitato = role === roles.admin || role === roles.developer || role === roles.magazziniere || role === roles.contabile || (isAgente(role) && parseInt(user.acquisti));

  const updateFromServer = () => {
    if (!abilitato) return;
    setLoading(true);
    apiProdotti.getAll(user.authToken)
      .then((data) => {
        setProdotti(data.map(c => ({
          id: c.id,
          name: c.name,
          description: c.description,
          division: c.division,
          imageUrl: c.image,
          quantity: c.quantity,
          cost: c.price,
          available: c.available,
        })
        ));
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateFromServer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ?
    (<ShopGrid
      elements={prodotti}
      cartName="vendita"
      displayName="prodotti"
      maxDescriptionLength={50}
      imageHeight={150}
      api={apiRichiesteVendita}
      richiediExtra={false}
      richiediDatiVendita={true}
      redirectTo="/status-vendita"
    />)
    : (<ShopGridSkeleton />);
};