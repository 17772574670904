import {post} from "./base";

export function getAll(accessToken) {
  return post("materiali/GetMateriali", {accessToken});
}

export async function add(data) {
  // data: FormData -> remember to add accessToken
  await post("materiali/AddMateriale", {accessToken: data['accessToken'], formBody: data});
  console.log(`Materiale ${data['id']} added`);
}
export async function remove(accessToken, idMateriale) {
  await post("materiali/DeleteMateriale", {jsonBody: {accessToken, idMateriale}});
  console.log(`Materiale ${idMateriale} deleted`);
}

export async function setAvailability(accessToken, idMateriale, value) {
  await post("materiali/UpdateAvailability", {jsonBody: {
    accessToken, 
    id: idMateriale, 
    available: value,
  }});
  console.log(`Materiale ${idMateriale} set as ${value ? 'available' : 'unavailable'}`);
}
