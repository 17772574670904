import {post} from "./base";
import { statusRichiesta } from "../config/config";

export function getAll(accessToken) {
    return post("richiesteMateriale/GetRichieste", {jsonBody: {accessToken}});
}

export async function add(accessToken, data) {
    // extra, cart
    await post("richiesteMateriale/AddRichiesta", {jsonBody: {...data, accessToken }});
    console.log(`Richiesta materiale added`);
}

export async function update(accessToken, idRichiesta, status) {
    await post("richiesteMateriale/UpdateStatusRichiesta", {jsonBody: {
        accessToken,
        idRichiesta,
        status, // statusRichiesta
    }});
    console.log(`Richiesta materiale ${idRichiesta} updated with state ${Object.keys(statusRichiesta).find((k) => statusRichiesta[k] === status)}`);
}

export async function extra(accessToken, idRichiesta, extra) {
    await post("richiesteMateriale/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        extra,
        updateType: 'extra',
    }});
    console.log(`Richiesta materiale ${idRichiesta} set as ${extra ? 'extra' : 'regular'}`);
}

export async function updateCartQuantity(accessToken, idRichiesta, idMateriale, quantity) {
    await post("richiesteMateriale/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        idMateriale,
        quantity,
        updateType: 'quantity',
    }});
    console.log(`Richiesta materiale ${idRichiesta} cart quantity updated for materiale ${idMateriale}`);
}

export async function removeFromCart(accessToken, idRichiesta, idMateriale) {
    await post("richiesteMateriale/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        idMateriale,
        updateType: 'delete',
    }});
    console.log(`Richiesta materiale ${idRichiesta} got materiale ${idMateriale} removed from cart`);
}
