import {post} from "./base";
import { statusRichiesta } from "../config/config";

export function getAll(accessToken) {
    return post("richiesteVendita/GetRichieste", {jsonBody: {accessToken}});
}

export async function add(accessToken, data) {
    // extra, cart
    await post("richiesteVendita/AddRichiesta", {jsonBody: {...data, accessToken }});
    console.log(`Richiesta vendita added`);
}

export async function update(accessToken, idRichiesta, status) {
    await post("richiesteVendita/UpdateStatusRichiesta", {jsonBody: {
        accessToken,
        idRichiesta,
        status, // statusRichiesta
    }});
    console.log(`Richiesta vendita ${idRichiesta} updated with state ${Object.keys(statusRichiesta).find((k) => statusRichiesta[k] === status)}`);
}

export async function updateCartQuantity(accessToken, idRichiesta, idProdotto, quantity) {
    await post("richiesteVendita/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        idProdotto,
        quantity,
        updateType: 'quantity',
    }});
    console.log(`Richiesta vendita ${idRichiesta} cart quantity updated for prodotto ${idProdotto}`);
}

export async function removeFromCart(accessToken, idRichiesta, idProdotto) {
    await post("richiesteVendita/UpdateOrder", {jsonBody: {
        accessToken,
        idRichiesta,
        idProdotto,
        updateType: 'delete',
    }});
    console.log(`Richiesta vendita ${idRichiesta} got prodotto ${idProdotto} removed from cart`);
}
