/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Skeleton } from "@material-ui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as apiMedici from "../../api/medici"
import * as apiServizi from "../../api/servizi"
import { icons, roles } from "../../../app/config/config";

const formFields = {
    description: {
        label: "Descrizione",
        placeholder: "Descrizione...",
        rows: 8
    },
    idMedico: {
        label: "Selezionare un medico",
        placeholder: "Medico...",
        newLabel: 'Aggiungi nuovo', 
        newLink: '/medici/#/new'
    },
    type: {
        label: "Tipo di servizio",
        placeholder: "Servizio...",
    },
    otherType: {
        label: "Altro tipo di servizio",
        placeholder: "Servizio...",
    },
    stimaCosto: {
        label: "Stima del costo del servizio",
        placeholder: "Inserisci una cifra",
    },
}

const actionsMessages = {
    send: "Invia richiesta",
    reset: "Reset",
};

const serviziTypes = [
    {
        value: 1,
        label: "Iscrizione congresso",
    },
    {
        value: 2,
        label: "Sponsorizzazione",
    },
    {
        value: 3,
        label: "Trasferta",
    },
    {
        value: 4,
        label: "Altro",
    },
]
const otherServizioType = 4;
const descriptionByType = {
    1: 'descrizione 1',
    2: 'descrizione 2',
    3: 'descrizione 3',
    4: ''
}
const infoByType = {
    0: {
        hasStimaCosto: false,
        hasMedico: false,
    },
    1: {
        hasStimaCosto: false,
        hasMedico: true,
        isMedicoNecessary: true,
    },
    2: {
        hasStimaCosto: true,
        hasMedico: false,
    },
    3: {
        hasStimaCosto: false,
        hasMedico: false,
    },
    4: {
        hasStimaCosto: false,
        hasMedico: true,
        isMedicoNecessary: false,
    },
}


const validationSchemaBuilder = (type) => yup.object({
    description: yup
        .string("Inserire una descrizione valida")
        .required("Inserire una descrizione valida")
        .min(3, "Minimo tre caratteri")
        .max(500, "Massimo cinquecento caratteri"),
    idMedico: infoByType[type].hasMedico
        ? infoByType[type].isMedicoNecessary
            ? yup.number("Inserire un medico valido")
                .positive("Selezionare un medico")
                .required("Seleziona un medico") 
            : yup.number("Inserire un medico valido")
                .moreThan(-1, "Selezionare un medico")
                .required("Seleziona un medico") 
        : undefined,
    stimaCosto: infoByType[type].hasStimaCosto
        ? yup.number("Inserire un costo valido")
            .positive("Inserire un costo positivo")
        : undefined,
    type: yup
        .number("Inserire un tipo valido")
        .positive("Selezionare un tipo")
        .required("Seleziona un tipo"),
    otherType: yup
        .string("Inserire un tipo di servizio valido")
        .when("type", {
            is: otherServizioType,
            then: yup.string()
                .required("Inserisci un tipo di servizio")
                .min(3, "Minimo tre caratteri")
                .max(50, "Massimo cinquanta caratteri")
        }),
});

const ServiziToolbar = ({
    submitCallback,
    resetCallback,
    loading,
    ...props
}) => {
    return (
        <div {...props}>
            <Button
                disabled={loading}
                onClick={submitCallback}
                className="float-right my-1 mx-1"
            >
                <span className="svg-icon svg-icon-md svg-icon-light-primary">
                    <SVG src={toAbsoluteUrl(icons.ok)} />
                </span>
                {actionsMessages.send}
            </Button>
            <Button
                disabled={loading}
                onClick={resetCallback}
                variant="secondary"
                className="float-right my-1 mx-1"
            >
                <span className="svg-icon svg-icon-md svg-icon-secondary">
                    <SVG src={toAbsoluteUrl(icons.reset)} />
                </span>
                {actionsMessages.reset}
            </Button>
        </div>
    );
};

export const Servizi = () => {
    const [loading, setLoading] = useState(false);
    const [medici, setMedici] = useState([]);
    const [richiestaLoading, setRichiestaLoading] = useState(false);
    const [richiestaWasSuccessful, setRichiestaWasSuccessful] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const initialState = {
        description: "",
        idMedico: 0,
        type: 1,
        otherType: "",
        stimaCosto: 0,
    };

    // User redux
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const role = parseInt(user.idRole);
    //const [schema, setSchema] = useState(validationSchemaBuilder(serviziTypes[0].value))

    const updateFromServer = () => {
        setLoading(true);
        apiMedici.getAll(user.authToken)
            .then((data) => setMedici(data.map((medico) => ({ value: medico.id, label: medico.fullname }))))
            .then(() => setLoading(false))
            .catch((e) => []); // TODO: NOTIFY THE USER OF THE ERROR
    };

    useEffect(() => {
        updateFromServer();
    }, []);

    const formik = useFormik({
        initialValues: initialState,
        enableReinitialize: true,
        validationSchema: () => validationSchemaBuilder(formik.values.type),
        onSubmit: (values) => {
            setRichiestaLoading(true);
            let typeId;
            let type;
            for (let { value, label } of serviziTypes) {
                if (value == values['type']) {
                    typeId = value;
                    type = label;
                    break;
                }
            }
            if (typeId == otherServizioType) {
                type = values['otherType'];
            }
            const result = { ...values, type };
            if (!infoByType[typeId].hasMedico) {
                result['idMedico'] = 0;
            }
            if (!infoByType[typeId].hasStimaCosto) {
                result['stimaCosto'] = 0;
            }
            apiServizi
                .add(user.authToken, result)
                .then(() => {
                    formik.handleReset();
                    setRichiestaWasSuccessful(true);
                    setRichiestaLoading(false);
                    setDialogOpen(true);
                })
                .catch((e) => {
                    setRichiestaWasSuccessful(false);
                    setRichiestaLoading(false);
                    setDialogOpen(true);
                });
        },
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    return (
        <>
        <Card className="card-custom gutter-b">
            <Card.Header className="border-0 py-5 row">
                <Card.Title className="float-left col-auto">
                    <h3 className="font-weight-bolder text-dark">
                        Richiedi un servizio
                    </h3>
                </Card.Title>
                <ServiziToolbar
                    submitCallback={formik.handleSubmit}
                    resetCallback={formik.handleReset}
                    loading={richiestaLoading}
                    className="float-right col-auto"
                />
            </Card.Header>
            <Card.Body className="pt-0">
                {!loading ? (
                    <Form className="row" onSubmit={formik.handleSubmit}>
                        <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                            <Form.Label className="col-12">
                                {formFields["type"].label}
                            </Form.Label>
                            <select
                                type="text"
                                name="type"
                                className={`form-control ${getInputClasses("type")}`}
                                {...formik.getFieldProps("type")}
                            >
                                <option value={0}>{formFields["type"].placeholder}</option>
                                {serviziTypes.map((e, key) => (
                                    <option key={key} value={e.value}>
                                        {e.label}
                                    </option>
                                ))}
                            </select>
                            {formik.touched["type"] && formik.errors["type"] ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors["type"]}</div>
                                </div>
                            ) : null}
                        </Form.Group>

                        {formik.values.type == otherServizioType ? (
                            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                                <Form.Label className="col-12">
                                    {formFields["otherType"].label}
                                </Form.Label>
                                <Form.Control
                                    name="otherType"
                                    placeholder={formFields["otherType"].placeholder}
                                    className={getInputClasses("otherType")}
                                    {...formik.getFieldProps("otherType")}
                                />
                                {formik.touched["otherType"] && formik.errors["otherType"] ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors["otherType"]}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>
                        ) : null}

                        <Form.Group className="col-12">
                            <Form.Label className="col-12">
                                {formFields["description"].label}
                            </Form.Label>
                            <div className="col-12 text-muted">
                                {descriptionByType[formik.values.type]}
                            </div>
                            <Form.Control
                                as="textarea"
                                name="description"
                                placeholder={formFields["description"].placeholder}
                                //value={description}
                                //onChange={(e) => setDescription(e.target.value)}
                                rows={formFields.description.rows}
                                style={{ resize: "none" }}
                                className={getInputClasses("description")}
                                {...formik.getFieldProps("description")}
                            />
                            {formik.touched["description"] && formik.errors["description"] ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors["description"]}
                                    </div>
                                </div>
                            ) : null}
                        </Form.Group>

                        {infoByType[formik.values.type].hasMedico ? 
                            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                                <Form.Label className="col-12">
                                    {formFields["idMedico"].label} 
                                    <Link to={formFields["idMedico"].newLink} style={{'float': 'right'}}>{formFields["idMedico"].newLabel}</Link>
                                </Form.Label>
                                <select
                                    type="text"
                                    name="idMedico"
                                    className={`form-control ${getInputClasses("idMedico")}`}
                                    {...formik.getFieldProps("idMedico")}
                                >
                                    <option value={0}>{formFields["idMedico"].placeholder}</option>
                                    {medici.map((e, key) => (
                                        <option key={key} value={e.value}>
                                            {e.label}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched["idMedico"] && formik.errors["idMedico"] ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors["idMedico"]}</div>
                                    </div>
                                ) : null}
                            </Form.Group> : null}
                        
                        {infoByType[formik.values.type].hasStimaCosto ? 
                            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                                <Form.Label className="col-12">
                                    {formFields["stimaCosto"].label}
                                </Form.Label>
                                <div className="d-flex align-items-center">
                                    <Form.Control
                                        type="text" 
                                        name="stimaCosto"
                                        placeholder={formFields["stimaCosto"].placeholder}
                                        className={`form-control ${getInputClasses("stimaCosto")}`}
                                        {...formik.getFieldProps("stimaCosto")}
                                        onInput={(e) => {
                                            // checks if a string is a positive number or empty
                                            let val = e.target.value.replace(',', '.').replace(/[^0-9.]/g, '');
                                            const shouldWait = val.endsWith('.');
                                            if (shouldWait && val.search(/\.(.*)(\.)/) >= 0) {
                                                val = val.substr(0, val.length - 1);
                                            }
                                            const parsed = shouldWait ? NaN : parseFloat(val);
                                            e.persist();
                                            console.log(e, e.target.value, val, parsed);
                                            if (!isNaN(parsed) && parsed >= 0) {
                                                e.target.value = Math.round(parsed * 100) / 100;
                                            } else {
                                                e.target.value = val;
                                            }
                                        }}
                                    />€
                                </div>
                                {formik.touched["stimaCosto"] && formik.errors["stimaCosto"] ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors["stimaCosto"]}</div>
                                    </div>
                                ) : null}
                            </Form.Group> : null}
                    </Form>
                ) : (
                    <div>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="rect" height={518} />
                    </div>
                )}
            </Card.Body>
        </Card>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="richiesta-dialog-title"
                aria-describedby="richiesta-dialog-description"
            >
                <DialogTitle id="richiesta-dialog-title">
                    {richiestaWasSuccessful ? "Richiesta inviata" : "Errore"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="richiesta-dialog-description">
                        {richiestaWasSuccessful ? (
                            <p>
                                La tua richiesta è stata effettuata correttamente, puoi
                                consultare le tue richieste nella tua sezione di{" "}
                                <Link to="/status-campionatura-servizi#servizi">Riepilogo</Link>
                            </p>
                        ) : "C'è stato un errore nel processare la richiesta, si prega di riprovare"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};