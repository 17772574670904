import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
//import { requestPassword } from "../_redux/authCrud";
import * as apiUtenti from "../../../api/users";

const forgotPasswordInitialValues = {
  email: "",
};
const resetPasswordInitialValues = {
  email: "",
  token: "",
  password: "",
};

function ForgotPassword(props) {
  
  const [isDone, setIsDone] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [email, setEmail] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Inserisci un'email valida")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required("Inserisci un'email valida"),
  });
  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Inserisci un'email valida")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required("Inserisci un'email valida"),
    token: Yup.string()
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required("Inserisci un codice valido"),
    password: Yup.string()
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required("Inserisci una password valida"),
  });

  const getInputClasses = (formik, fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  
  const ForgotPasswordForm = ({setEmail}) => {
    const formik = useFormik({
      initialValues: forgotPasswordInitialValues,
      validationSchema: ForgotPasswordSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        apiUtenti.recoverPassword(values.email)
          .then(() => {
            setIsRequested(true);
            setIsDone(false);
            setEmail(values.email);
          })
          .catch(() => {
            setIsRequested(false);
            setIsDone(false);
            setSubmitting(false);
            setStatus(
              'Mail non trovata'
            );
          });
      },
    });
    return (
      <>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              placeholder="Email..."
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              formik, "email"
            )}`}
            name="email"
            onClick={() => setError(false)}
            {...formik.getFieldProps("email")}
          />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Recupera
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Annulla
              </button>
            </Link>
          </div>
        </form>
      </>
    );
  }
  const ResetPasswordForm = ({email}) => {
    const formik = useFormik({
      initialValues: {...resetPasswordInitialValues, email},
      validationSchema: ResetPasswordSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        apiUtenti.checkTokenPassword(values.token, values.email, values.password)
          .then(() => setIsDone(true))
          .catch(() => {
            setIsRequested(true);
            setIsDone(false);
            setSubmitting(false);
            setStatus(
              'Token non valido o scaduto'
            );
          });
      },
    });
    return (
      <>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              placeholder="Email..."
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                formik, "email"
              )}`}
              name="email"
              disabled
              onClick={() => setError(false)}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              type="text"
              placeholder="Codice..."
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                formik, "token"
              )}`}
              name="token"
              onClick={() => setError(false)}
              {...formik.getFieldProps("token")}
            />
            {formik.touched.token && formik.errors.token ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.token}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              placeholder="Nuova password..."
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                formik, "password"
              )}`}
              name="password"
              onClick={() => setError(false)}
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Cambia password
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Annulla
              </button>
            </Link>
          </div>
        </form>
      </>
    );
  }

  return (
    <> {isDone 
      ? <Redirect to='/auth/login' /> 
      : (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Password dimenticata?</h3>
            <div className="text-muted font-weight-bold">
            {!isRequested ? 'Inserisci la tua email per recuperarla' : 'Inserisci il codice mandato tramite email'}
            </div>
          </div>
          {!isRequested ? <ForgotPasswordForm setEmail={setEmail} /> : <ResetPasswordForm email={email} />}
        </div> 
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
