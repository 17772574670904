import arrConfig from "../config/config";

function ApiException(code, message) {
    const error = new Error(`${code} - ${message}`);
    error.code = code;
    error.plainMessage = message;
    return error;
}

function populate(formData, data) {
    for (const k in data) {
        formData.set(k, data[k]);
    }
    return formData;
}

export async function post(endpoint, {accessToken, jsonBody, formBody}) { 
    if (accessToken !== undefined) {
        jsonBody = jsonBody !== undefined 
            ? {...jsonBody, accessToken} 
            : {accessToken};
    }
    const body = formBody !== undefined 
        ? populate(formBody, jsonBody) 
        : JSON.stringify(jsonBody);
    const option = {
        headers: {
            Authorization: arrConfig.keyApi,
        },
        method: "POST",
        body: body,
    };
    let response;
    let isJson = true;
    try {
        const result = await fetch(
            arrConfig.apiUrl + endpoint + ".php",
            option
        );
        response = await result.text();
        if (response.length > 0) {
            try {
                response = JSON.parse(response);
            } catch {
                isJson = false;
            }
        } else {
            response = undefined;
        }
        if (result.ok && isJson) {
            return response;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
    const error = new ApiException(
        isJson ? response.code : 500, 
        isJson ? response.message : response,
    );
    console.log(error);
    throw error;
}