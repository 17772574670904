import React, { useState, useEffect, createRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import timeGrid from "@fullcalendar/timegrid";
import itLocale from "@fullcalendar/core/locales/it";
import useMobileDetect from "use-mobile-detect-hook";
import moment from "moment-timezone";
import Swal from "sweetalert2";

import * as apiCalendario from "../../api/calendario";
import { ModalVisualizzaEvento } from "./ModalVisualizzaEvento";

export default function Calendar({ refreshCounter }) {
  const detectMobile = useMobileDetect();

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [show, setShow] = useState(false);

  const calendarRef = createRef();

  useEffect(() => {
    calendarRef.current.getApi().refetchEvents();
  }, [refreshCounter]);

  function getEvents(info, successCallback, failureCallback) {
    apiCalendario.getEventi(user.authToken, moment(info.start).format("YYYY-MM-DD"), moment(info.end).format("YYYY-MM-DD"))
      .then((data) => successCallback(data.map((el) => ({
        id: el.id,
        title: el.title,
        start: moment(el.startDate).toISOString(),
        end: moment(el.endDate).toISOString(),
        allDay: !!el.allDay,
        backgroundColor: el.color,
        color: el.color,
        extendedProps: el
      }))))
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Errore nel caricamento degli eventi",
          confirmButtonColor: "#3699ff",
          confirmButtonText: "Chiudi",
        });
        failureCallback(err);
      });
  }

  return (
    <>
      <ModalVisualizzaEvento
        show={show}
        setShow={setShow}
        selectedEvent={selectedEvent}
      />
      <FullCalendar
        plugins={[
          momentTimezonePlugin,
          dayGridPlugin,
          interactionPlugin,
          timeGrid,
        ]}
        timeZone="Europe/Rome"
        eventClick={({event}) => {
          setShow(true);
          setSelectedEvent(event);
        }}
        headerToolbar={
          detectMobile.isDesktop()
            ? {
                start: "prev,next today",
                center: "title",
                end: "dayGridMonth,timeGridWeek,timeGridDay",
              }
            : {
                start: "prev,next",
                center: "title",
                end: "timeGridWeek,timeGridDay",
              }
        }
        handleWindowResize={true}
        weekends={true}
        //dayCellDidMount={dateRender}
        themeSystem="bootstrap"
        titleFormat={
          detectMobile.isDesktop()
            ? { year: "numeric", month: "long" }
            : { day: "numeric", month: "numeric" }
        }
        locales={itLocale}
        locale={"it"}
        events={getEvents}
        initialView={detectMobile.isDesktop() ? "dayGridMonth" : "timeGridWeek"}
        selectable={true}
        editable={true}
        ref={calendarRef}
      />
    </>
  );
}
