import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, shallowEqual } from "react-redux";
import * as apiFile from "../../api/file";
import * as apiUtenti from "../../api/users";
import {
  roles,
  icons,
  categorieFile,
  nomiCategorieFile,
  nomiCategorieFilePlural,
  fileVisibilityGroups,
  isAgente,
} from "../../config/config";
import Swal from "sweetalert2";

const validationSchema = yup.object({
  name: yup
    .string("Inserire un nome valido")
    .required("Inserire un nome valido")
    .min(3, "Minimo tre caratteri")
    .max(100, "Massimo cinquanta caratteri"),
  idAgente: yup
    .number("Inserire un utente valido")
    .moreThan(-3, "Selezionare un utente o un gruppo")
    .required("Seleziona un utente"),
});

const actionsMessages = {
  new: "Carica",
  edit: "Modifica",
  reset: "Reset",
};

const formFields = {
  name: {
    label: "Nome",
    placeholder: "Nome file...",
  },
  idAgente: {
    label: "Visibile a ",
  },
  file: {
    label: "File",
  },
};

const EditNewFileToolbar = ({
  submitCallback,
  resetCallback,
  closeCallback,
  loading,
  existingFile,
  type,
  ...props
}) => {
  return (
    <div {...props}>
      <Button
        disabled={loading}
        onClick={submitCallback}
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-light-primary">
          <SVG src={toAbsoluteUrl(icons.ok)} />
        </span>
        {existingFile ? actionsMessages.edit : actionsMessages.new}
      </Button>
      <Button
        disabled={loading}
        onClick={resetCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.reset)} />
        </span>
        {actionsMessages.reset}
      </Button>
      <Button
        onClick={closeCallback}
        variant="secondary"
        className="float-right my-1 mx-1"
      >
        <span className="svg-icon svg-icon-md svg-icon-secondary">
          <SVG src={toAbsoluteUrl(icons.go_back)} />
        </span>
        {nomiCategorieFilePlural[type]}
      </Button>
    </div>
  );
};

export const EditNewFile = (props) => {
  const [loading, setLoading] = useState(false);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);
  const isAdmin = role === roles.admin || role === roles.developer;
  const isGraficoHandlingManuale = role === roles.grafico && props.type === categorieFile.manuali;

  var initialState = {
    name: "",
    idAgente: 0,
  };
  const existingFile = props.file !== false;
  if (existingFile) {
    Object.assign(initialState, props.file);
  }
  const [name, setName] = useState(initialState.name);
  const [idAgente, setIdAgente] = useState(initialState.idAgente);
  const [newFile, setNewFile] = useState(false);

  const handleReset = () => {
    setName(initialState.name);
    setIdAgente(initialState.idAgente);
    setNewFile(false);
    formik.handleReset();
  };

  const [agenti, setAgenti] = useState([]);
  const rolesList = props.type === categorieFile.documenti 
    ? [roles.admin, roles.developer, roles.magazziniere, roles.dipendente, roles.grafico]
    : [roles.admin, roles.developer, roles.agenteCompleto, roles.agenteRoydermal, roles.agenteRoypet];
  const customFileVisibilityGroups = props.type === categorieFile.documenti 
    ? [fileVisibilityGroups[0]]
    : fileVisibilityGroups
  useEffect(() => {
    if (isAdmin) {
      apiUtenti.getAll(props.token)
      .then((data) => [...data, {id: user.id, role: user.idRole, lastname: user.lastname, fullname: user.firstname + " " + user.lastname}])
      .then((data) => {
        var parsed = data
          //.filter((u) => rolesList.indexOf(parseInt(u.role)) > 0)
          .map((u) => ({ value: u.id, label: u.fullname, isAgente: isAgente(parseInt(u.role)), lastname: u.lastname }));
        parsed = parsed.sort((a, b) => (a.lastname.toLowerCase() > b.lastname.toLowerCase()) ? 1 : -1);
        setAgenti(parsed);
      });
    }
    /*eslint-disable-next-line*/
  }, []);

  const formik = useFormik({
    initialValues: {
      name: name,
      idAgente: idAgente,
      file: newFile,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log('before: %o', values);
      values.idAgente = idAgente;
      values.name = name;
      console.log('after: %o', values);
      if (existingFile && values.name === initialState.name && values.idAgente === initialState.idAgente) {
        Swal.fire({
          icon: "error",
          title: "Nessuna modifica fatta",
          confirmButtonColor: "#3699ff",
          confirmButtonText: "Chiudi",
        });
        return;
      }
      if (!existingFile && newFile === false) {
        Swal.fire({
          icon: "error",
          title: "Nessun file selezionato",
          confirmButtonColor: "#3699ff",
          confirmButtonText: "Chiudi",
        });
        return;
      }
      const agenteDropdownItem = values.idAgente > 0 
        ? agenti.find((a) => a.value === parseInt(values.idAgente))
        : fileVisibilityGroups.find((g) => g.value === parseInt(values.idAgente));
      const fileUploadedForStr = ((isAdmin || isGraficoHandlingManuale) && (typeof agenteDropdownItem !== "undefined")) 
        ? " per " + agenteDropdownItem.label 
        : "";
      Swal.fire({
        title: "Richiesta",
        html: `<p>Sei sicuro di voler ${
          existingFile ? "modificare il file" : "caricare il file"
        } "${name}"${fileUploadedForStr}?</p>`,
        confirmButtonColor: "#3699ff",
        cancelButtonColor: "#f64e60",
        showDenyButton: true,
        confirmButtonText: "Conferma",
        denyButtonText: `Annulla`,
      }).then((result) => {
        if (result.isConfirmed) {
          var request;
          if (existingFile) {
            request = apiFile
              .edit(
                user.authToken,
                props.file.id,
                values.name,
                (isAdmin || isGraficoHandlingManuale) ? parseInt(values.idAgente) : undefined,
                props.type
              );
          } else {
            let formResult = new FormData();
            formResult.append("name", values.name);
            if (isAdmin || isGraficoHandlingManuale) formResult.append("idAgente", parseInt(values.idAgente));
            formResult.append("type", props.type);
            formResult.append("accessToken", user.authToken);
            formResult.append("file", newFile);
            request = apiFile
              .add(formResult);
          }
          request.then(() => {
            setLoading(false);
            props.triggerServerUpdate();
            props.hideEditPage();
            Swal.fire({
              icon: "success",
              title: existingFile ? "File modificato con successo" : "File caricato con successo",
              confirmButtonColor: "#3699ff",
              confirmButtonText: "Chiudi",
            });
          }).catch((err) => {
            setSubmitting(false);
            setLoading(false);
            //setStatus(err.message);
            Swal.fire({
              icon: "error",
              title: err.plainMessage,
              confirmButtonColor: "#3699ff",
              confirmButtonText: "Chiudi",
            });
          });
        } else if (result.isDenied) {
          // Swal.fire({
          //   title: "Operazione annullata",
          //   confirmButtonColor: "#3699ff",
          //   confirmButtonText: "Chiudi",
          //   icon: "danger",
          // });
        }
      });
    },
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  return (
    <Card className="card-custom gutter-b">
      <Card.Header className="border-0 py-5 row">
        <Card.Title className="float-left col-auto">
          <h3 className="font-weight-bolder text-dark">
            {existingFile ? actionsMessages.edit : actionsMessages.new}{" "}
            {nomiCategorieFile[props.type]}
          </h3>
        </Card.Title>
        <EditNewFileToolbar
          submitCallback={formik.handleSubmit}
          resetCallback={handleReset}
          closeCallback={props.hideEditPage}
          loading={loading}
          existingFile={existingFile}
          type={props.type}
          className="float-right col-auto"
        />
      </Card.Header>
      <Card.Body className="pt-0">
        <Form className="row" onSubmit={formik.handleSubmit}>
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">{formFields.name.label}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder={formFields.name.placeholder}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={getInputClasses("name")}
              /* {...formik.getFieldProps("name")} */
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </Form.Group>
          {(isAdmin || isGraficoHandlingManuale) ? (<Form.Group className="col-xl-6 col-lg-6 col-sm-12">
            <Form.Label className="col-12">
              {formFields.idAgente.label}
            </Form.Label>
            <select
              value={idAgente}
              onChange={(e) =>
                e !== null ? setIdAgente(e.target.value) : null
              }
              name="idAgente"
              className={`form-control ${getInputClasses("idAgente")}`}
              /* {...formik.getFieldProps("idAgente")} */
            >
              {customFileVisibilityGroups.map((group) => (
                <option value={group.value}>{group.label}</option>
              ))}
              {(props.type === categorieFile.documenti ? agenti : agenti.filter((u) => u.isAgente)).map((agente) => (
                <option value={agente.value}>{agente.label}</option>
              ))}
            </select>
            {formik.touched.idAgente && formik.errors.idAgente ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.idAgente}</div>
              </div>
            ) : null}
          </Form.Group>) : <></>}
          {existingFile ? null : (
            <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">
                {formFields.file.label}
              </Form.Label>
              <div className="custom-file">
                <Form.File
                  type="file"
                  className="custom-file-input"
                  name="file"
                  id="fileUploadField"
                  style={{ visibility: "hidden" }}
                  onChange={(e) => setNewFile(e.target.files[0])}
                />
                <label
                  className={`col-12 custom-file-label ${
                    newFile ? null : "text-muted"
                  }`}
                  htmlFor="fileUploadField"
                >
                  {newFile ? newFile.name : "Seleziona file"}
                </label>
              </div>
            </Form.Group>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};
