import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { Utenti } from "./utenti/Utenti";
import { CostsPage } from "./CostsPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";

export default function adminPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from admin root URL to /users */
          <Redirect exact={true} from="/admin" to="/admin/utenti" />
        }
        <ContentRoute path="/admin/utenti" component={Utenti} />
        <ContentRoute path="/admin/costs" component={CostsPage} />
      </Switch>
    </Suspense>
  );
}
