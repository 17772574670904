import {post} from "./base";

export function login(email, password) {
  return post("users/Login", {jsonBody: {email, password}}); 
}
export function changePassword(accessToken, id, currentPassword, password) {
  return post("users/ChangePassword", {jsonBody: {
    accessToken, 
    id, 
    currentPassword, 
    password,
  }}); 
}
export function recoverPassword(email) {
  return post("users/RecuperaPassword", {jsonBody: {email}});
}
export function checkTokenPassword(token, email, password) {
  return post("users/ControllaTokenPassword", {jsonBody: {token, email, password}}); 
}
export function getUserByToken(accessToken) {
  return post("users/GetUserByToken", {accessToken}); 
}

export async function getAll(accessToken) {
  const result = await post("users/GetUsers", {accessToken});
  return result.map((u) => ({
      ...u,
      fullname: u.firstname + " " + u.lastname,
  }));
  // "id", "role", "firstname", "lastname", "fullname", "email", "phone", "address", "region"
}

export function getRegioni(accessToken) {
  return post("geolocal/GetRegioni", {accessToken});
}

export async function add(accessToken, data) {
  await post("users/AddUser", {jsonBody: {...data, accessToken }});
  console.log(`Utente added`);
}

export async function remove(accessToken, idUtente) {
  await post("users/DeleteUser", {jsonBody: {accessToken, idUtente}});
  console.log(`Utente ${idUtente} removed`);
}
