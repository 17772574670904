/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { EditNewMateriale } from "./components/EditNewMateriale";
import { Chip, Avatar } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Skeleton } from "@material-ui/lab";
import * as PropTypes from "prop-types";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger, Button, Modal } from "react-bootstrap";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { roles, icons } from "../../config/config";
import * as apiMateriali from "../../api/materiali";

// Useful sub-components
const TableComponent = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className="table table-hover table-head-custom table-vertical-center overflow-hidden"
  />
);

const Toolbar = ({ addCallback, ...props }) => {
  return (
    <div {...props}>
      <Button onClick={addCallback} className="float-right">
        Aggiungi nuovo materiale pubblicitario
      </Button>
    </div>
  );
};

const FilterCell = (props) => {
  const { column } = props;
  if (column.name === "actions") {
    const ActionsFilterCell = ({ filter, onFilter }) => (
      <th style={{ fontWeight: "normal" }}>
        <input disabled style={{ visibility: "hidden" }} />
      </th>
    );
    ActionsFilterCell.propTypes = {
      filter: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
      onFilter: PropTypes.func.isRequired,
    };
    ActionsFilterCell.defaultProps = {
      filter: null,
    };
    return <ActionsFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const DateFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => moment(value).format("DD MMMM YYYY")}
    {...props}
  />
);
const PriceFormattedColumn = (props) => (
  <DataTypeProvider
  formatterComponent={({ value }) => `${(typeof value == "string" ? parseFloat(value) : value).toFixed(2)}€`}
    {...props}
  />
);
const AvailabilityFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <>
        {parseInt(value)
          ? <Avatar style={{ width: 25, height: 25, backgroundColor: 'var(--success)', }}>
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG src={toAbsoluteUrl(icons.ok)} />
            </span>
            {/* <i className="ki ki-plus icon-white"></i> */}
          </Avatar>
          : null}
      </>
    )}
    {...props}
  />
);

const NameFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ row, value }) => {
      if (row.image !== "" && row.image !== "0") {
        return (<>
          <div
            style={{display: 'flex', justifyContent: 'space-between'}}
          >
            <div
              style={{
                alignSelf: 'center',
                marginRight: '5px',
              }}
            >
              {value}
            </div>
            <div
              style={{
                display: 'inline-block',
                borderRadius: '8px',
                height: '100%',
                textAlign: 'center',
                overflow: 'hidden',
                alignSelf: 'center',
                minWidth: '30px',
              }}
            >
              <img
                src={
                  row.image.startsWith("/")
                    ? process.env.REACT_APP_API_URL + row.image
                    : row.image
                }
                style={{
                  height: '30px',
                  margin: '0 auto',
                }}
                alt=""
              />
            </div>
          </div>
        </>);
      } else {
        return (<>{value}</>);
      }
    }}
    {...props}
  />
);

const ActionsColumn = ({ isAdmin, editCallback, availableCallback, notAvailableCallback, deleteCallback }) => (
  <DataTypeProvider
    for={["actions"]}
    formatterComponent={({ value }) => (
      <> 
        { isAdmin 
          ? <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">Modifica</Tooltip>}
          >
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
              onClick={() => editCallback(value.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl(icons.edit)} />
              </span>
            </a>
          </OverlayTrigger>
          : null }
        <OverlayTrigger
          overlay={<Tooltip id="products-available-tooltip">{value.available ? "Imposta come non disponibile" : "Imposta come disponibile"}</Tooltip>}
        >
          {/*eslint-disable-next-line*/}
          <a
            className={"btn btn-icon btn-light btn-sm mr-3 " + (value.available ? "btn-hover-danger" : "btn-hover-success")}
            onClick={value.available ? () => notAvailableCallback(value.id) : () => availableCallback(value.id)}
          >
            <span className={"svg-icon svg-icon-md " + (value.available ? "svg-icon-danger" : "svg-icon-success")}>
              <SVG src={value.available ? toAbsoluteUrl(icons.close) : toAbsoluteUrl(icons.ok)} />
            </span>
          </a>
        </OverlayTrigger>
        { isAdmin 
          ? <OverlayTrigger
            overlay={<Tooltip id="products-delete-tooltip">Elimina</Tooltip>}
          >
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => deleteCallback(value.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl(icons.delete)} />
              </span>
            </a>
          </OverlayTrigger>
          : null }
      </>
    )}
  />
);

const AziendaColumn = ({ value }) => {
  value = parseInt(value);
  var rp = value === 1 || value === 3;
  var rd = value === 2 || value === 3;
  return (
    <div className="pl-3">
      {rp ? <Chip
        style={{borderColor: "#313d85", color: "#313d85"}}
        variant="outlined"
        avatar={
          <Avatar
            alt="RP"
            src={toAbsoluteUrl("/media/products/roypet.png")}
          />
        }
        label="Roypet"
      /> : null}
      {rd ? <Chip
        style={{borderColor: "#C92020", color: "#C92020"}}
        variant="outlined"
        avatar={
          <Avatar
            alt="RD"
            src={toAbsoluteUrl("/media/products/roydermal.png")}
          />
        }
        label="Roydermal"
      /> : null}
    </div>
  );
}

export const AziendaFormattedColumn = (props) => (
  <DataTypeProvider formatterComponent={AziendaColumn} {...props} />
);

const DeleteModal = ({ name, show, hideCallback, deleteCallback }) => (
  <Modal show={show} onHide={hideCallback} className="card-custom gutter-b">
    <Modal.Header className="border-0 py-5 row">
      <Modal.Title>
        <h3 className="font-weight-bolder text-dark">Elimina materiale pubblicitario</h3>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-4">
      Sei sicuro di voler eliminare definitivamente "{name}"
    </Modal.Body>
    <Modal.Footer className="border-0 py-5 row">
      <Button onClick={deleteCallback} variant="danger" className="float-right">
        Elimina
      </Button>
      <Button
        onClick={hideCallback}
        variant="secondary"
        className="float-right"
      >
        Annulla
      </Button>
    </Modal.Footer>
  </Modal>
);

// Columns definition
const columns = [
  { name: "name", title: "Nome" },
  { name: "price", title: "Prezzo" },
  { name: "available", title: "Disponibilità" },
  { name: "division", title: "Azienda" },
  { name: "dateCreated", title: "Data creazione" },
  { name: "actions", title: "Azioni" },
];
const columnExtensions = [
  { columnName: "name", wordWrapEnabled: true},
  { columnName: "dateCreated", wordWrapEnabled: true },
];

// Messages
const tableMessages = {
  noData: "Nessun materiale pubblicitario inserto",
};
const filterRowMessages = {
  filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
  showAll: "Tutte",
  rowsPerPage: "Righe per pagina",
  info: "Da {from} a {to} ({count} richieste)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "id", direction: "desc" }];

export const TabellaMateriale = () => {
  // table data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [_internalUpdateCount, _setInternalUpdateCount] = useState(0);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);

  const updateFromServer = () => {
    setLoading(true);
    apiMateriali.getAll(user.authToken)
      // row.actions is passed to the btn constructors which passes it to the callbacks
      .then((data) => setRows(data.map((row) => ({ ...row, actions: { id: row.id, available: row.available } }))))
      .then(() => setLoading(false));
  };
  const triggerServerUpdate = () => _setInternalUpdateCount(_internalUpdateCount + 1);
  useEffect(() => {
    updateFromServer();
  }, [_internalUpdateCount]);

  // edit page
  const [showEditPage, setShowEditPage] = useState(false);
  const [forEdit, setForEdit] = useState(false);
  // delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // bind history to views to allow deep-linking
  const { location, push } = useHistory();
  useEffect(() => {
    if (!loading) {
      if (location.hash && location.hash === "#/new") {
        setForEdit(false);
        setShowEditPage(true);
        setShowDeleteModal(false);
      } else if (location.hash && location.hash.startsWith("#/delete/")) {
        const idForEdit = parseInt(location.hash.substring(9));
        if (!isNaN(idForEdit)) {
          const row = rows.find((r) => r.id == idForEdit);
          if (row !== undefined) {
            setForEdit(row);
            setShowEditPage(false);
            setShowDeleteModal(true);
          }
        }
      } else if (location.hash && location.hash.startsWith("#/edit/")) {
        const idForEdit = parseInt(location.hash.substring(7));
        if (!isNaN(idForEdit)) {
          const row = rows.find((r) => r.id == idForEdit);
          if (row !== undefined) {
            setForEdit(row);
            setShowEditPage(true);
            setShowDeleteModal(false);
          }
        }
      } else {
        setForEdit(false);
        setShowEditPage(false);
        setShowDeleteModal(false);
      }
    }
    /*eslint-disable-next-line*/
  }, [location.hash, loading]);

  // paging and sorting
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState(initialSorting);

  if (!showEditPage) {
    return (
      <>
        <DeleteModal
          show={showDeleteModal}
          name={forEdit.name}
          hideCallback={() => {
            // setShowDeleteModal(false);
            push('#/');
          }}
          deleteCallback={() => {
            apiMateriali.remove(user.authToken, forEdit.id).then(() => {
              // setShowDeleteModal(false);
              push('#/');
              triggerServerUpdate();
            }
            );
          }}
        />
        <Card className="card-custom gutter-b">
          <Card.Header className="border-0 py-5 row">
            <Card.Title className="float-left col-auto">
              <h3 className="font-weight-bolder text-dark">Materiale pubblicitario</h3>
            </Card.Title>
            <Toolbar
              addCallback={() => {
                // setForEdit(false);
                // setShowEditPage(true);
                push('#/new');
              }}
              className="float-right col-auto"
            />
          </Card.Header>
          <Card.Body className="pt-0">
            {!loading ? (
              <Grid rows={rows} columns={columns}>
                <ActionsColumn
                  isAdmin={role === roles.admin}
                  editCallback={(id) => {
                    // setForEdit(rows.find((r) => r.id === id));
                    // setShowEditPage(true);
                    push(`#/edit/${id}`);
                  }}
                  availableCallback={(id) => {
                    apiMateriali.setAvailability(user.authToken, id, 1).then(triggerServerUpdate);
                  }}
                  notAvailableCallback={(id) => {
                    apiMateriali.setAvailability(user.authToken, id, 0).then(triggerServerUpdate);
                  }}
                  deleteCallback={(id) => {
                    // setForEdit(rows.find((r) => r.id === id));
                    // setShowDeleteModal(true);
                    push(`#/delete/${id}`);
                  }}
                />
                <NameFormattedColumn for={["name"]} />
                <DateFormattedColumn for={["dateCreated"]} />
                <PriceFormattedColumn for={["price"]} />
                <AvailabilityFormattedColumn for={["available"]} />
                <AziendaFormattedColumn for={["division"]} />
                <FilteringState />
                <IntegratedFiltering />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting />
                <Table
                  messages={tableMessages}
                  columnExtensions={columnExtensions}
                  tableComponent={TableComponent}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow
                  messages={filterRowMessages}
                  cellComponent={FilterCell}
                />
                <PagingPanel
                  messages={pagingPanelMessages}
                  pageSizes={pageSizes}
                />
              </Grid>
            ) : (
              <div>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={518} />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  } else
    return (
      <EditNewMateriale
        materiale={forEdit}
        hideEditPage={() => {
          // setShowEditPage(false);
          push('#/');
        }}
        triggerServerUpdate={triggerServerUpdate}
      />
    );
};
