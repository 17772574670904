/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
/*eslint-disable-next-line*/
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import * as PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link, useLocation } from "react-router-dom";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import SVG from "react-inlinesvg";
/*eslint-disable-next-line*/
import { Card, Tooltip, OverlayTrigger, Button, Modal } from "react-bootstrap";
import moment from "moment-timezone";

import {
  roles,
  icons,
  categorieFile,
  nomiCategorieFile,
  nomiCategorieFilePlural,
  isAgente,
  fileVisibilityGroups,
} from "../../config/config";
//import * as apiCampioni from "../../api/campioni";
import * as apiFile from "../../api/file";
import { EditNewFile } from "./EditNewFile";

// Useful sub-components
const TableComponent = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className="table table-hover table-head-custom table-vertical-center overflow-hidden"
  />
);

const FilterCell = (props) => {
  const { column } = props;
  if (column.name === "actions") {
    const ActionsFilterCell = ({ filter, onFilter }) => (
      <th style={{ fontWeight: "normal" }}>
        <input disabled style={{ visibility: "hidden" }} />
      </th>
    );
    ActionsFilterCell.propTypes = {
      filter: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
      onFilter: PropTypes.func.isRequired,
    };
    ActionsFilterCell.defaultProps = {
      filter: null,
    };
    return <ActionsFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const DateFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      moment(value).format("DD MMMM YYYY, HH:mm")
    }
    {...props}
  />
);

const useStyles = makeStyles({
  public: {
    width: 25,
    height: 25,
    backgroundColor: "var(--danger)",
  },
});

const PublicColumn = ({ value }) => {
  const classes = useStyles();
  /*eslint-disable-next-line*/
  if (value == true)
    return (
      <Avatar className={classes.public}>
        <span className="svg-icon svg-icon-md svg-icon-white">
          {" "}
          <SVG src={toAbsoluteUrl(icons.ok)} />
        </span>
        {/* <i className="ki ki-plus icon-white"></i> */}
      </Avatar>
    );
  return <></>;
};

export const PublicFormattedColumn = (props) => (
  <DataTypeProvider formatterComponent={PublicColumn} {...props} />
);

const ActionsColumn = ({
  canDelete,
  canEdit,
  editCallback,
  deleteCallback,
}) => (
  <DataTypeProvider
    for={["actions"]}
    formatterComponent={({ value }) => (
      <>
        <OverlayTrigger overlay={<Tooltip>Apri</Tooltip>}>
          {/*eslint-disable-next-line*/}
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            href={encodeURI(process.env.REACT_APP_API_URL + value.url)}
            target="_blank"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl(icons.download)} />
            </span>
          </a>
        </OverlayTrigger>
        {canEdit ? (
          <OverlayTrigger overlay={<Tooltip>Modifica</Tooltip>}>
            <a
              className={"btn btn-icon btn-light btn-hover-info btn-sm mr-3 "}
              onClick={() => editCallback(value.id)}
            >
              <span className={"svg-icon svg-icon-md svg-icon-info"}>
                <SVG src={toAbsoluteUrl(icons.edit)} />
              </span>
            </a>
          </OverlayTrigger>
        ) : null}
        {canDelete ? (
          <OverlayTrigger overlay={<Tooltip>Elimina</Tooltip>}>
            <a
              className={"btn btn-icon btn-light btn-hover-danger btn-sm mr-3 "}
              onClick={() => deleteCallback(value.id)}
            >
              <span className={"svg-icon svg-icon-md svg-icon-danger"}>
                <SVG src={toAbsoluteUrl(icons.delete)} />
              </span>
            </a>
          </OverlayTrigger>
        ) : null}
      </>
    )}
  />
);

const DeleteModal = ({ name, show, hideCallback, deleteCallback }) => (
  <Modal show={show} onHide={hideCallback} className="card-custom gutter-b">
    <Modal.Header className="border-0 py-5 row">
      <Modal.Title>
        <h3 className="font-weight-bolder text-dark">Elimina file</h3>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-4">
      Sei sicuro di voler eliminare definitivamente "{name}"
    </Modal.Body>
    <Modal.Footer className="border-0 py-5 row">
      <Button onClick={deleteCallback} variant="danger" className="float-right">
        Elimina
      </Button>
      <Button
        onClick={hideCallback}
        variant="secondary"
        className="float-right"
      >
        Annulla
      </Button>
    </Modal.Footer>
  </Modal>
);

// Columns definition
const fileColumns = [
  { name: "name", title: "Nome" },
  { name: "uploadDate", title: "Caricato il" },
  { name: "actions", title: "Azioni" },
];
const fileColumnExtensions = [
  { columnName: "name", wordWrapEnabled: true },
  { columnName: "uploadDate", wordWrapEnabled: true },
  {
    columnName: "actions",
    wordWrapEnabled: true,
    align: "right",
    width: "auto",
  },
  { columnName: "agente", wordWrapEnabled: true },
  { columnName: "public", width: "110" },
];
const sortExtensions = [{ columnName: "actions", sortingEnabled: false }];

// Messages
const tableMessages = {
  noData: "Nessun file caricato",
};
const filterRowMessages = {
  filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
  showAll: "Tutti",
  rowsPerPage: "File per pagina",
  info: "Da {from} a {to} ({count} file)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "uploadDate", direction: "desc" }];

const hashCategorie = Object.fromEntries(
  Object.entries(nomiCategorieFilePlural).map((el) => [
    el[0],
    "#/" + el[1].replace(" ", "-").toLowerCase(),
  ])
);

export const File = () => {
  // table data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [_internalUpdateCount, _setInternalUpdateCount] = useState(0);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);
  const isAdmin = role === roles.admin || role === roles.developer;
  
  const userCategorieFile =
    isAdmin
      ? Object.values(categorieFile)
      : isAgente(role)
        ? Object.values(categorieFile).filter(
          (v) => v !== categorieFile.documenti
        )
        : role === roles.grafico
          ? [categorieFile.manuali, categorieFile.documenti]
          : [categorieFile.documenti]; // if admin -> all, if agente -> al,l but "documenti", if grafico -> "documenti", "manuali", else -> "documenti"
  const [tab, setTab] = useState(userCategorieFile[0]);

  // to link url anchor to tab
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      let newTab = Object.keys(hashCategorie).find(
        (key) => hashCategorie[key] === hash
      );
      if (
        newTab !== undefined &&
        userCategorieFile.includes(parseInt(newTab))
      ) {
        setTab(parseInt(newTab));
      } 
    } else {
      setTab(userCategorieFile[0]);
    }
    /*eslint-disable-next-line*/
  }, [hash]);

  const isGraficoHandlingManuale = role === roles.grafico && tab === categorieFile.manuali;
  const uploadAllowed = isAdmin || (!isAgente(role) && tab == categorieFile.documenti) || isGraficoHandlingManuale;
  const editAllowed = uploadAllowed;
  const deleteAllowed = isAdmin || (role == roles.grafico && tab == categorieFile.manuali);  // Modifica qui
  
  const columns = [
    ...fileColumns.slice(0, 1),
    isAdmin || isGraficoHandlingManuale
      ? { name: "agente", title: "Agente" }
      : { name: "public", title: "Pubblico" },
    ...fileColumns.slice(1),
  ];
  


  // edit page
  const [showEditPage, setShowEditPage] = useState(false);
  const [fileForEdit, setFileForEdit] = useState(false);
  // delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updateFromServer = () => {
    setLoading(true);
    apiFile
      .getAll(user.authToken)
      .catch((err) => [])
      .then((files) => {
        files.sort((a,b) => new Date(b.uploadDate) - new Date(a.uploadDate));
        setRows(
          files.map((f) => ({
            ...f,
            agente: f.agente == null ? "<" + fileVisibilityGroups.find((g) => g.value == f.idAgente).label + ">" : f.agente,
            public: f.agente == null,
            actions: { id: f.id, url: f.url },
          }))
        
        )
      }
      )
      .then(() => setLoading(false));
  };
  const triggerServerUpdate = () =>
    _setInternalUpdateCount(_internalUpdateCount + 1);
  useEffect(() => {
    updateFromServer();
    /*eslint-disable-next-line*/
  }, [_internalUpdateCount]);

  // paging and sorting
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState(initialSorting);

  /*eslint-disable-next-line*/
  if (!showEditPage) {
    return (
      <>
        <DeleteModal
          show={showDeleteModal}
          name={fileForEdit.name}
          hideCallback={() => setShowDeleteModal(false)}
          deleteCallback={() => {
            apiFile.remove(user.authToken, fileForEdit.id).then(() => {
              setShowDeleteModal(false);
              triggerServerUpdate();
            });
          }}
        />
        <Card className="card-custom gutter-b">
          <Tabs
            value={tab}
            onChange={(_, t) => setTab(t)}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant="fullWidth"
          >
            {userCategorieFile.map(function (v, key) {
              return (
                <Tab
                  label={nomiCategorieFilePlural[v]}
                  value={v}
                  to={hashCategorie[v]}
                  component={Link}
                  key={key}
                />
              );
            })}
          </Tabs>
        </Card>
        <Card className="card-custom gutter-b">
          <Card.Header className="border-0 py-5 row">
            <Card.Title className="float-left col-auto">
              <h3 className="font-weight-bolder text-dark">
                Tabella {nomiCategorieFilePlural[tab]}
              </h3>
            </Card.Title>
            {uploadAllowed ? (
              <div className="float-right col-auto">
                <Button
                  onClick={() => {
                    setFileForEdit(false);
                    setShowEditPage(true);
                  }}
                  className="float-right my-1 mx-1"
                >
                  <span className="svg-icon svg-icon-md svg-icon-light-primary">
                    <SVG src={toAbsoluteUrl(icons.upload)} />
                  </span>
                  Carica {nomiCategorieFile[tab]}
                </Button>
              </div>
            ) : null}
          </Card.Header>
          <Card.Body className="pt-0">
            {!loading ? (
              <Grid
                rows={[...rows.filter((f) => parseInt(f.type) === tab)]}
                columns={columns}
              >
                <ActionsColumn
                  canEdit={editAllowed}
                  canDelete={deleteAllowed}
                  editCallback={(id) => {
                    setFileForEdit(rows.find((r) => r.id === id));
                    setShowEditPage(true);
                  }}
                  deleteCallback={(id) => {
                    setFileForEdit(rows.find((r) => r.id === id));
                    setShowDeleteModal(true);
                  }}
                />
                <PublicFormattedColumn for={["public"]} />
                <DateFormattedColumn for={["uploadDate"]} />
                <FilteringState />
                <IntegratedFiltering />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <SortingState
                  sorting={sorting}
                  onSortingChange={setSorting}
                  columnExtensions={sortExtensions}
                />
                <IntegratedSorting />
                <Table
                  messages={tableMessages}
                  tableComponent={TableComponent}
                  columnExtensions={fileColumnExtensions}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow
                  messages={filterRowMessages}
                  cellComponent={FilterCell}
                />
                <PagingPanel
                  messages={pagingPanelMessages}
                  pageSizes={pageSizes}
                />
              </Grid>
            ) : (
              <div>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={518} />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  } else {
    return (
      <EditNewFile
        file={fileForEdit}
        type={tab}
        token={user.authToken}
        hideEditPage={() => setShowEditPage(false)}
        triggerServerUpdate={triggerServerUpdate}
      />
    );
  }
};
